import React from 'react';
import { Tiles } from 'elcano';
import { Product } from './Product';

export const Products = ({ products, onClick, showPrice }) => (
  <Tiles columns={[2, null, 4]}>
    {products &&
      products.map((product) => (
        <Product
          key={product.id}
          product={product}
          onClick={onClick}
          showPrice={showPrice}
        />
      ))}
  </Tiles>
);

export default Products;
