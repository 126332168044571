import { GalleryImage, PixaImage } from '../models';

export const mapPixaImages = (images: PixaImage[]): GalleryImage[] =>
  images.map(image => ({
    key: image.id.toString(),
    src: image.webformatURL,
    width: image.imageWidth,
    height: image.imageHeight,
  }));

export default mapPixaImages;
