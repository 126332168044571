import React from 'react';
import { Button, Flex, Box, Text, Tiles } from 'elcano';
import { Icon } from '../Icon';
import { Tool } from '../../models';

export interface InsertMenuProps {
  selectedTool: Tool;
  onSelect: (tool: Tool) => void;
  t: (key: string) => string;
}

interface ToolMap {
  type: Tool;
  icon: JSX.Element;
  name: string;
}

export function InsertMenu({ onSelect, t, selectedTool }: InsertMenuProps) {
  const tools: ToolMap[] = [
    {
      type: 'product',
      icon: <Icon icon="triangle" active size={30} />,
      name: t('common:product'),
    },
    {
      type: 'text',
      icon: <Icon icon="text" active size={30} />,
      name: t('common:text'),
    },
    {
      type: 'upload',
      icon: <Icon icon="another-icon" active size={30} />,
      name: t('common:upload'),
    },
    {
      type: 'shape',
      icon: <Icon icon="circle" active size={30} />,
      name: t('common:shape'),
    },
    {
      type: 'elements',
      icon: <Icon icon="rect" active size={30} />,
      name: t('common:elements'),
    },
    {
      type: 'photo',
      icon: <Icon icon="image" active size={30} />,
      name: t('common:photo'),
    },
  ];
  return (
    <Flex my={4} mx={1} justifyContent="left" width={1}>
      <Tiles mt={4} columns={[3, 6]} height="20%">
        {tools.map(({ type, icon, name }) => (
          <Box key={type} textAlign="center">
            <Button
              variant="round"
              onClick={() => onSelect(type)}
              key={type}
              sx={{
                height: '56px',
                width: '56px',
                boxShadow:
                  type === selectedTool
                    ? 'none'
                    : 'rgb(0 0 0 / 13%) 0px 0px 4px',
                border:
                  type === selectedTool
                    ? '5px solid #EFC327'
                    : '5px solid transparent',
                ':hover': {
                  border: '5px solid #EFC327',
                  boxShadow: 'none',
                },
                ':focus': {
                  outline: 'none',
                },
              }}
            >
              {icon}
            </Button>
            <Text>{name}</Text>
          </Box>
        ))}
      </Tiles>
    </Flex>
  );
}

export default InsertMenu;
