import { Product, Variation } from '../models';

export const findVariation = (option: string, product: Product) => {
  const variation = product.variations.find(x =>
    x.attributes.some(attribute => attribute.option === option)
  );

  return variation || null;
};

export const findVariationByImageId = (imageId: string, product: Product) => {
  const variation = product.variations.find(x =>
    x.images?.some(image => image.id === imageId)
  );

  return variation || null;
};

export const getInitialVariant = ({ attributes }: Product) => {
  if (
    attributes &&
    attributes.length &&
    attributes[0].options &&
    attributes[0].options.length
  ) {
    return attributes[0].options[0];
  }
  return null;
};

export const getImages = (variation: Variation, product: Product) => {
  if (variation && variation.images && variation.images.length) {
    return variation.images;
  }
  return product && product.images;
};

export const getImage = (variation: Variation, product: Product) => {
  if (variation && variation.image) {
    return variation.image;
  }
  return product.image && product.image;
};
