import React, { ChangeEvent, useEffect, useState } from 'react';
import has from 'lodash/has';
import styled from '@emotion/styled';
import { Textarea, Select, Flex, Button } from 'elcano';

import PropertyGroup from './PropertyGroup';
import SwitchState from './SwitchState';
import { Columns } from './Columns';
import { fontFamilies } from '../../data';
import { Font } from '../../models';

export interface TextPanelProps {
  add: (type: string, options: any) => void;
  editMode?: boolean;
  onChange?: (key: string, value: any) => void;
  object?: any;
  t: (key: string) => string;
}

const StyledTextarea = styled(Textarea)`
  font-family: inherit;
  width: 100%;
  min-width: 100%;
  min-height: 80px;
  resize: vertical;
`;

export const TextPanel = ({
  add,
  onChange,
  object,
  editMode,
  t,
}: TextPanelProps) => {
  const [inputValue, setInputValue] = useState('');
  const [fontFamily, setFontFamily] = useState('Alegreya');

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (editMode) {
      return onChange && onChange('text', value);
    }
    return setInputValue(value);
  };

  const handleChange = (key: string, e: ChangeEvent | boolean) => {
    if (onChange) {
      onChange(key, e);
    }
  };

  useEffect(() => {
    if (object && object.fontFamily) {
      setFontFamily(object.fontFamily);
    }
  }, [object]);

  const addText = () => {
    const textObject = {
      text: inputValue,
      rotate: 0,
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      fill: 'black',
      fontSize: 20,
      fontFamily,
    };
    add('text', textObject);
    setInputValue('');
  };

  const handleFontFamilyChange = ({
    target: { value },
  }: {
    target: { value: any };
  }) => {
    setFontFamily(value);
    if (editMode) {
      // eslint-disable-next-line no-unused-expressions
      onChange && onChange('fontFamily', value);
    }
  };

  const sortFonts = (f1: Font, f2: Font) => {
    if (f1.name.toLowerCase() > f2.name.toLowerCase()) {
      return 1;
    }
    return f1.name.toLowerCase() < f2.name.toLowerCase() ? -1 : 0;
  };

  return (
    <PropertyGroup showIf={!editMode || has(object, 'text')}>
      <Flex flexWrap="wrap">
        <Columns label={t('common:text')} show>
          <Flex my={3} flexWrap="wrap" width={1}>
            <StyledTextarea
              value={editMode ? object?.text : inputValue}
              onChange={onTextChange}
              id="text"
              name="text"
            />
            {!editMode && (
              <Button my={3} variant="outline" onClick={() => addText()}>
                {t('common:add_text')}
              </Button>
            )}
          </Flex>
        </Columns>
        <Columns label={t('common:style')} show={editMode}>
          <Flex my={3}>
            {object?.text && (
              <SwitchState
                icon="format-bold"
                defaultValue="normal"
                nextState="bold"
                value={object.fontWeight}
                onChange={(e: ChangeEvent) => handleChange('fontWeight', e)}
              />
            )}
            {object?.text && (
              <SwitchState
                icon="format-italic"
                defaultValue="normal"
                nextState="italic"
                value={object.fontStyle}
                onChange={(e: ChangeEvent) => handleChange('fontStyle', e)}
              />
            )}
            {object?.text && (
              <SwitchState
                icon="format-underline"
                defaultValue={false}
                nextState="underline"
                value={!!object.underline}
                onChange={() => handleChange('underline', !object.underline)}
              />
            )}
          </Flex>
        </Columns>
        <Columns label={t('common:font')} show>
          <Flex my={3}>
            <Select
              id="font"
              name="font"
              value={fontFamily}
              onChange={handleFontFamilyChange}
              width={200}
            >
              {fontFamilies.sort(sortFonts).map(({ name, family }) => (
                <option key={family} value={family}>
                  {name}
                </option>
              ))}
            </Select>
          </Flex>
        </Columns>
      </Flex>
    </PropertyGroup>
  );
};

export default TextPanel;
