import React from 'react';
import { Icon } from '../Icon';

export interface SwitchStateProps {
  value: any;
  defaultValue: any;
  nextState: any;
  icon: any;
  onChange: any;
}

const SwitchState = ({
  value,
  defaultValue,
  icon,
  onChange,
  nextState,
}: SwitchStateProps) => {
  const selected = value && value !== defaultValue;
  const newValue = selected ? defaultValue : nextState;
  return (
    <Icon
      size={28}
      icon={icon}
      active={selected}
      onClick={() => onChange(newValue)}
    />
  );
};

export default SwitchState;
