import React, { useState } from 'react';
import { Box, Button, Flex, Heading, RoundButton, SlideUp, Text } from 'elcano';
import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { AddPanelList } from './AddPanelList';
import { Lang, Tool } from '../../models';

export interface MobileInsertMenuProps {
  add: (type: string, object: any) => void;
  show: boolean;
  onClose: () => void;
  t: (key: string) => string;
  lang: Lang;
}

interface ToolMap {
  type: Tool;
  icon: JSX.Element;
  name: string;
}

const StyledRoundButton = styled(RoundButton)`
  transform: rotate(-90deg);
  margin-left: 0;
`;

export function MobileInsertMenu({
  add,
  show,
  onClose,
  t,
  lang,
}: MobileInsertMenuProps) {
  const [tool, setTool] = useState<ToolMap | null>();
  const tools: ToolMap[] = [
    {
      type: 'text',
      icon: <Icon icon="text" active size={30} />,
      name: t('common:text'),
    },
    {
      type: 'upload',
      icon: <Icon icon="another-icon" active size={30} />,
      name: t('common:upload'),
    },
    {
      type: 'shape',
      icon: <Icon icon="circle" active size={30} />,
      name: t('common:shape'),
    },
    {
      type: 'elements',
      icon: <Icon icon="rect" active size={30} />,
      name: t('common:elements'),
    },
    {
      type: 'photo',
      icon: <Icon icon="image" active size={30} />,
      name: t('common:photo'),
    },
  ];

  return (
    <SlideUp
      show={show}
      onClose={() => {
        onClose();
        setTool(null);
      }}
      heading={
        tool ? (
          <Flex alignItems="center">
            <StyledRoundButton onClick={() => setTool(null)}>
              <Icon icon="another-icon" active size={30} />
            </StyledRoundButton>
            <Heading>{tool.name}</Heading>
          </Flex>
        ) : (
          <Heading m={3}>{t('common:add')}</Heading>
        )
      }
    >
      {!tool ? (
        <Flex my={4} mx={1} justifyContent="space-evenly" flexWrap="wrap">
          {tools.map(toolComponent => (
            <Box
              key={toolComponent.type}
              textAlign="center"
              width="fit-content"
            >
              <Button
                variant="round"
                onClick={() => setTool(toolComponent)}
                key={toolComponent.type}
              >
                {toolComponent.icon}
              </Button>
              <Text>{toolComponent.name}</Text>
            </Box>
          ))}
        </Flex>
      ) : (
        <Box>
          <AddPanelList
            selectedTool={tool.type}
            add={(type, object) => {
              setTool(null);
              add(type, object);
            }}
            t={t}
            lang={lang}
            isMobile
          />
        </Box>
      )}
    </SlideUp>
  );
}

export default MobileInsertMenu;
