import React from 'react';
import { Button } from 'elcano';
import Spinner from './Spinner';

function RoundButton({ children, loading = false, ...rest }) {
  return (
    <Button variant="round" {...rest}>
      {!loading ? children : <Spinner />}
    </Button>
  );
}

export default RoundButton;
