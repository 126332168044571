import React from 'react';
import { Icon, SVGProps, defaultIconProps } from './Icon';

export const Star = ({
  title = 'Star',
  size = defaultIconProps.size,
  color = defaultIconProps.color,
}: SVGProps) => (
  <Icon title={title}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.201432 7.85425C0.0324796 7.73123 -0.0397528 7.51452 0.021606 7.31473L0.101529 7.06496C0.159961 6.86756 0.335635 6.72781 0.541103 6.71527L6.92493 6.20573L9.37256 0.321007C9.45466 0.114853 9.66074 -0.0144568 9.88206 0.00129377H10.1418C10.3505 -0.00313381 10.539 0.12523 10.6114 0.321007L13.069 6.20573L19.4528 6.71527C19.6583 6.72781 19.8339 6.86756 19.8924 7.06496L19.9723 7.31473C20.0403 7.50821 19.9805 7.72355 19.8224 7.85425L15.0071 11.9905L16.4857 18.195C16.5344 18.3936 16.4596 18.6021 16.2959 18.7245L16.0061 18.8843C15.8371 18.9986 15.6157 18.9986 15.4467 18.8843L10.0119 15.5873L4.54723 18.9143C4.37823 19.0286 4.15676 19.0286 3.98777 18.9143L3.76798 18.7645C3.60419 18.642 3.52945 18.4335 3.57816 18.2349L5.01677 11.9905L0.201432 7.85425Z"
        fill={color}
      />
    </svg>
  </Icon>
);

export default Star;
