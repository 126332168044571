import React, {
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  useCallback,
} from 'react';
import { Flex, Box, Button, Input, Spinner } from 'elcano';
import Gallery from 'react-photo-gallery';
import { fetchImages } from '../../api';
import { getImgUrl, replaceUmlaute } from '../../utils';
import PropertyGroup from './PropertyGroup';
import { GalleryImage, ImageType, Lang } from '../../models';
import { mapPixaImages } from '../../mappers';
import { GalleryImage as GalleryImageComponent } from '../GalleryImage';

const IMAGE_BOX_ID = 'image-box';

export interface ImagePanelProps {
  add: (type: string, object: any) => void;
  editMode?: boolean;
  type?: ImageType;
  t: (key: string) => string;
  lang: Lang;
}

export const ImagePanel = ({
  editMode,
  add,
  type,
  t,
  lang,
}: ImagePanelProps) => {
  const [search, updateSearch] = useState('');
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [page, setPage] = useState<number>(0);
  const [endOfPage, setEndOfPage] = useState<boolean>(false);
  const loader = useRef(null);

  // here we handle what happens when user scrolls to Load More div
  // in this case we just update page variable
  const handleObserver = (entities: IntersectionObserverEntry[]) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage(pageNumber => pageNumber + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current!);
    }
  }, []);

  const getImages = useCallback(
    async (featured: boolean = false, pageNumber = 1) => {
      const stripped = search.replace(' ', '+');
      const term = replaceUmlaute(stripped);

      const hits = await fetchImages({
        page: pageNumber,
        term,
        lang,
        type: type as ImageType,
        featured,
      });

      const additionalImages = mapPixaImages(hits);

      return additionalImages;
    },
    [lang, search, type]
  );

  const scroll = useCallback(
    async (pageNumber: number) => {
      const additionalImages = await getImages(!search, pageNumber);
      setImages(prevState =>
        prevState ? prevState.concat(additionalImages) : additionalImages
      );

      if (!additionalImages.length) {
        setEndOfPage(true);
      }
    },
    [getImages, search]
  );

  useEffect(() => {
    if (page !== 0) {
      scroll(page);
    }
  }, [page]);

  const addImage = (src: string) => {
    if (src) {
      const imageObject = {
        href: getImgUrl(src),
        width: 100,
        height: 100,
      };

      add('image', imageObject);
    }
  };

  const handleSearch = async () => {
    setImages([]);
    setPage(1);
    setEndOfPage(false);
    const imageBox = document.getElementById(IMAGE_BOX_ID);
    if (imageBox) {
      imageBox.scrollTop = 0;
    }
  };

  return (
    <PropertyGroup showIf={!editMode} height="55vh">
      <Flex flexWrap="wrap" height="100%">
        <Box width={1}>
          <Flex>
            <Input
              placeholder={t('common:search_placeholder')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                // eslint-disable-next-line prettier/prettier
                updateSearch(e.target.value)}
            />
            <Box width={10} />
            <Button width={[1 / 3, 1 / 4]} onClick={() => handleSearch()}>
              {t('common:search')}
            </Button>
          </Flex>
        </Box>
        <Box
          height="80%"
          overflowY="scroll"
          overflowX="hidden"
          id={IMAGE_BOX_ID}
        >
          <Gallery
            photos={images}
            renderImage={({ left, top, photo }) => (
              <GalleryImageComponent
                key={photo.key}
                margin="2px"
                photo={photo}
                left={left}
                top={top}
                onClick={addImage}
                direction="row"
              />
            )}
          />
          <Flex width={1} justifyContent="center" ref={loader}>
            {!endOfPage ? <Spinner /> : null}
          </Flex>
        </Box>
      </Flex>
    </PropertyGroup>
  );
};

export default ImagePanel;
