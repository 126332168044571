import React from 'react';
import { Icon, SVGProps, defaultIconProps } from './Icon';

export const ChristmasTree = ({
  title = 'ChristmasTree',
  size = defaultIconProps.size,
  color = defaultIconProps.color,
}: SVGProps) => (
  <Icon title={title}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.911 15.3801L13.7714 15.6301C13.6351 15.8588 13.3892 15.9992 13.1234 16.0001H8.13893L8.41807 19.4601C8.44851 19.7175 8.27372 19.9542 8.0193 20.0001H6.0255C5.88683 20.0008 5.75439 19.9424 5.6612 19.8393C5.56801 19.7363 5.52287 19.5984 5.53702 19.4601L5.85603 16.0001H0.871518C0.605783 15.9992 0.359895 15.8588 0.223532 15.6301L0.0839657 15.3801C-0.0556005 15.1401 -0.0556005 14.8601 0.353129 14.1601L2.82545 10.0001H2.20737C1.94163 9.99925 1.69574 9.85885 1.55938 9.63006L1.41981 9.38006C1.28025 9.14006 1.28025 8.86006 1.68898 8.16006L6.23485 0.370056C6.36824 0.138247 6.61603 -0.00323834 6.88284 5.63109e-05H7.16197C7.42522 0.000362877 7.66836 0.141365 7.79999 0.370056L12.4356 8.37006C12.7546 8.91006 12.7546 9.19006 12.615 9.43006L12.4755 9.68006C12.3391 9.90885 12.0932 10.0492 11.8275 10.0501H11.2094L13.7316 14.3701C14.0506 14.8601 14.0506 15.1401 13.911 15.3801Z"
        fill={color}
      />
    </svg>
  </Icon>
);

export default ChristmasTree;
