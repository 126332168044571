import React from 'react';
import styled from '@emotion/styled';

export interface ColorProps {
  color?: string;
  value: string;
  onSelect: (value: string) => void;
}

const ColorComponent = styled.a`
  width: 18;
  height: 18;
  border-radius: '50%';
  display: 'inline-block';
  background: 'white';
  margin-right: 3;
`;

const Color = styled.span`
  background-color: ${props => props.color || '000'};
  margin-left: 2px;
  margin-top: 2px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000;
`;

export const ColorInput = ({ color, value, onSelect }: ColorProps) => {
  return (
    <ColorComponent onClick={() => onSelect(value)}>
      <Color color={color} />
    </ColorComponent>
  );
};

export default ColorInput;
