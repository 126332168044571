import React from 'react';
import { Flex, Box, Heading, SlideUp } from 'elcano';
import { TextPanel } from './TextPanel';
import { ActionPanel } from './ActionPanel';
import StylePanel from './StylePanel';
import SizePanel from './SizePanel';
import { UploadPanel } from './UploadPanel';
import ShapePanel from './ShapePanel';
import { ImagePanel } from './ImagePanel';
import { Product, Tool, Variation, Image, Lang } from '../../models';

const panels = [
  { Component: TextPanel, id: 'text' },
  { Component: UploadPanel, id: 'upload' },
  { Component: ShapePanel, id: 'shape' },
  { Component: ImagePanel, id: 'vector' },
  { Component: ImagePanel, id: 'photo' },
  { Component: StylePanel, id: 'style' },
  { Component: SizePanel, id: 'size' },
  { Component: ActionPanel, id: 'action' },
];

export interface MobilePanelListProps {
  id?: string;
  t: (key: string) => string;
  lang: Lang;
  product: Product;
  variation: Variation;
  image: Image;
  images: Image[];
  selectedTool: Tool;
  add: (type: string, object: any) => void;
  onChange: (key: string, value: any) => void;
  object: any;
  onArrange: (e: any) => void;
  onRemove: () => void;
  onClearAll: () => void;
  onCopy: () => void;
  alignVertically: () => void;
  alignHorizontally: () => void;
  updateAlignment: () => void;
  handleSelect: (varia: string) => void;
  handleImageSelect: (img: Image) => void;
  isHorizontal: boolean;
  show: boolean;
  onClose: () => void;
}

export const MobilePanelList = ({
  selectedTool,
  show,
  onClose,
  onRemove,
  onClearAll,
  t,
  ...rest
}: MobilePanelListProps) => {
  const remove = () => {
    onRemove();
    onClose();
  };

  const clearAll = () => {
    onClearAll();
    onClose();
  };
  return (
    <SlideUp
      show={show}
      onClose={onClose}
      heading={<Heading m={3}>{t('common:edit')}</Heading>}
    >
      <Flex width={1}>
        <Box width={1}>
          {panels.map(Panel => (
            <Panel.Component
              key={Panel.id}
              t={t}
              {...rest}
              onRemove={remove}
              onClearAll={clearAll}
              editMode
              isMobile
            />
          ))}
        </Box>
      </Flex>
    </SlideUp>
  );
};

export default MobilePanelList;
