import React from 'react';
import { Box, Flex, SideSlide, Direction } from 'elcano';

export interface DescriptionModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  description: string;
}

export const DescriptionModal = ({
  show,
  setShow,
  description,
}: DescriptionModalProps) => (
  <SideSlide
    show={show}
    onClose={() => setShow(false)}
    direction={Direction.RIGHT}
  >
    <Box>
      <Flex>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Flex>
    </Box>
  </SideSlide>
);

export default DescriptionModal;
