/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Box, Flex, RoundButton } from 'elcano';
import { fabric } from 'fabric';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"fabric/fabric-impl"' has no exported member 'Object'.
// eslint-disable-next-line import/no-unresolved
import { Object } from 'fabric/fabric-impl';
import { Tool, Arrange, Image as ImageInterface } from '../../models';
import {
  StaticRoundButtonContainer,
  StyledRoundButton,
} from './DesignerStyles';

import { Icon } from '../Icon';
import {
  InsertMenu,
  MobileInsertMenu,
  MobilePanelList,
  PanelList,
  ProductPanel,
} from '../panels';
import { DesignerPanelProps } from './types';
import { DescriptionModal } from '../description-modal';

export const DesignerPanels = ({
  id,
  t,
  lang,
  canvas,
  image,
  variation,
  selectVariation,
  selectImage,
  saveDesign,
  saveToMyDesigns,
  setAddSlideUp,
  setSelected,
  setEditSlideUp,
  setIsHorizontal,
  handleColorSelect,
  selected,
  isHorizontal,
  addSlideUp,
  editSlideUp,
  images,
  product,
  mobile,
  imageWidth,
  imageHeight,
}: DesignerPanelProps) => {
  const [tool, selectTool] = useState<Tool>('product');
  const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false);

  const saveCanvas = () => {
    const design = canvas?.toJSON();
    if (design) {
      saveDesign(id, design);
    }
  };

  const onArrange = (arrangeType: Arrange) => {
    const object = canvas?.getActiveObject();

    if (!object) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return arrangeType === 'back'
      ? canvas?.sendToBack(object)
      : canvas?.bringToFront(object);
  };

  const handleObjectChange = (key: any, value: string) => {
    const object = canvas?.getActiveObject();
    const updated = object?.set(key, value);
    canvas?.renderAll();

    if (updated) {
      setSelected(() => {
        const updatedSelection = {
          ...updated,
          [key]: value,
        } as Object;
        return updatedSelection;
      });
    }
  };

  const removeCurrent = () => {
    const activeObjects = canvas?.getActiveObjects();
    if (activeObjects) {
      canvas?.remove(...activeObjects);
      canvas?.discardActiveObject().renderAll();
    }
  };

  const clearAll = () => canvas?.clear();

  const alignVertically = () => {
    const object = canvas?.getActiveObject();
    object?.centerV();
  };

  const alignHorizontally = () => {
    const object = canvas?.getActiveObject();
    object?.centerH();
  };

  const updateAlignment = () => {
    if (isHorizontal) {
      canvas?.setHeight(imageWidth);
      canvas?.setWidth(imageHeight);
    } else {
      canvas?.setHeight(imageHeight);
      canvas?.setWidth(imageWidth);
    }
    canvas?.renderAll();
    setIsHorizontal(!isHorizontal);
  };

  const onCopy = () => {
    const obj = canvas?.getActiveObject();
    obj?.clone((c: Object) => {
      canvas?.add(
        c.set({ left: (obj?.left || 0) + 10, top: (obj?.top || 0) + 10 }),
      );
    });
  };

  const newObject = (type: string, object: any) => {
    if (type === 'image') {
      return fabric.Image.fromURL(object.href, (img: any) => {
        img.scaleToWidth(60);
        canvas?.add(img);
        canvas?.setActiveObject(img);
      });
    }

    let fabricObject;
    switch (type) {
      case 'text':
        fabricObject = new fabric.IText(object.text);
        break;
      case 'rect':
        fabricObject = new fabric.Rect(object);
        break;
      case 'circle':
        fabricObject = new fabric.Circle(object);
        break;
      case 'triangle':
        fabricObject = new fabric.Triangle(object);
        break;
      case 'path':
        fabricObject = new fabric.Path(object.path, object);
        break;
      default:
        return null;
    }

    canvas?.add(fabricObject);
    return canvas?.setActiveObject(fabricObject);
  };

  const updateTool = (selectedTool: Tool) => {
    selectTool(selectedTool);
    setSelected(null);
    return canvas?.discardActiveObject().renderAll();
  };

  const handleSelect = (varia: string) => {
    canvas?.discardActiveObject().renderAll();
    saveCanvas();
    selectVariation(varia);
  };

  const handleImageSelect = (img: ImageInterface) => {
    canvas?.discardActiveObject().renderAll();
    saveCanvas();
    selectImage(img);
  };

  const transitionToTop = () => window.scrollTo(0, 0);

  const onSave = () => {
    const design = canvas?.toJSON();
    if (design && saveToMyDesigns) {
      saveToMyDesigns(id, design);
    }
  };

  return (
    <>
      {mobile ? (
        <Flex>
          {!!selected && (
            <StaticRoundButtonContainer right="5rem">
              <RoundButton
                onClick={() => {
                  setEditSlideUp(true);
                  transitionToTop();
                }}
              >
                <Icon icon="pencil" />
              </RoundButton>
            </StaticRoundButtonContainer>
          )}
          <StaticRoundButtonContainer>
            <StyledRoundButton
              onClick={() => {
                setAddSlideUp(true);
                transitionToTop();
              }}
            >
              <Icon icon="plus" />
            </StyledRoundButton>
          </StaticRoundButtonContainer>
          <MobilePanelList
            show={editSlideUp}
            onClose={() => setEditSlideUp(false)}
            product={product}
            variation={variation}
            images={images}
            image={image}
            handleSelect={handleSelect}
            object={selected}
            onArrange={(e) => onArrange(e)}
            onChange={(key, value) => handleObjectChange(key, value)}
            handleImageSelect={handleImageSelect}
            onRemove={removeCurrent}
            onClearAll={clearAll}
            alignVertically={alignVertically}
            alignHorizontally={alignHorizontally}
            updateAlignment={updateAlignment}
            onCopy={onCopy}
            selectedTool={tool}
            add={(type, object) => newObject(type, object)}
            isHorizontal={isHorizontal}
            t={t}
            lang={lang}
          />
          <MobileInsertMenu
            show={addSlideUp}
            onClose={() => setAddSlideUp(false)}
            add={(type, object) => {
              newObject(type, object);
              setAddSlideUp(false);
            }}
            t={t}
            lang={lang}
          />
          <Box mb={6}>
            <ProductPanel
              t={t}
              editMode={false}
              product={product}
              variation={variation}
              images={images}
              image={image}
              handleSelect={handleSelect}
              updateAlignment={updateAlignment}
              handleImageSelect={handleImageSelect}
              onSave={saveToMyDesigns && onSave}
              isHorizontal={isHorizontal}
              showDescriptionModal={() => setShowDescriptionModal(true)}
              handleColorSelect={handleColorSelect}
            />
          </Box>
        </Flex>
      ) : (
        <Box width={[1, 1, 1, 1 / 2]}>
          <InsertMenu t={t} onSelect={updateTool} selectedTool={tool} />
          <PanelList
            t={t}
            lang={lang}
            product={product}
            variation={variation}
            images={images}
            image={image}
            handleSelect={handleSelect}
            object={selected}
            onArrange={(e) => onArrange(e)}
            onChange={(key, value) => handleObjectChange(key, value)}
            handleImageSelect={handleImageSelect}
            onRemove={removeCurrent}
            onClearAll={clearAll}
            alignVertically={alignVertically}
            alignHorizontally={alignHorizontally}
            updateAlignment={updateAlignment}
            onSave={saveToMyDesigns && onSave}
            onCopy={onCopy}
            editMode={!!selected}
            selectedTool={tool}
            add={(type, object) => newObject(type, object)}
            isHorizontal={isHorizontal}
            showDescriptionModal={() => setShowDescriptionModal(true)}
            handleColorSelect={handleColorSelect}
          />
        </Box>
      )}
      {product && product.description && (
        <DescriptionModal
          show={showDescriptionModal}
          setShow={setShowDescriptionModal}
          description={product.description}
        />
      )}
    </>
  );
};

export default DesignerPanels;
