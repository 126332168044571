import React from 'react';
import { Flex } from 'elcano';
import styled from '@emotion/styled';
import { Product } from '../../models';
import { ColorInput } from '../ColorInput';

export interface ColorSelectProps {
  product: Product;
  handleSelect: (varia: string) => void;
}

const ColorInputContainer = styled(Flex)`
  cursor: pointer;
`;

export const ColorSelect = ({ product, handleSelect }: ColorSelectProps) => {
  const getColor = () => {
    const colors = product.variations.filter(
      (v, i, a) => a.findIndex(t => t.color === v.color) === i
    );

    return colors.map((varia, index) => (
      <ColorInputContainer
        key={varia.variationId}
        justifyContent="center"
        ml={index > 0 && 4}
      >
        <ColorInput
          color={varia.color}
          value={varia.variationId}
          onSelect={() => handleSelect(varia.name)}
        />
      </ColorInputContainer>
    ));
  };

  return <>{getColor()}</>;
};
