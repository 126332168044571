import React from 'react';
import { Button } from 'elcano';
import styled from '@emotion/styled';
import Spinner from './Spinner';

const LoadingButton = styled(Button)`
  opacity: ${(props) => (props.loading ? '0.6' : '1')};
  transition: 0.3s;
  cursor: ${(props) => (props.loading ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LoadingButtonComponent({ children, loading, ...rest }) {
  return (
    <LoadingButton {...rest} disabled={loading} loading={loading}>
      {!loading ? children : <Spinner />}
    </LoadingButton>
  );
}

export default LoadingButtonComponent;
