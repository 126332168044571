import React from 'react';
import { Box, Flex, Heading } from 'elcano';

export interface ColumnsProps {
  show?: boolean;
  label?: string;
  children?: any;
}

export const Columns = ({ label, show, children }: ColumnsProps) => {
  if (show) {
    return (
      <Flex width={1} my={10} alignItems="center" flexWrap="wrap">
        <Heading fontSize={20} mx={3} width={1}>
          {label}
        </Heading>
        <Box mx={3} width={1}>
          {children}
        </Box>
      </Flex>
    );
  }
  return null;
};

export default Columns;
