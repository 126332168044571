import React from 'react';
import { ChromePicker } from 'react-color';
import styled from '@emotion/styled';

export interface MobileColorInputProps {
  onChange: any;
  value: any;
}

const ColorPopover = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const MobileColorInput = ({
  value,
  onChange,
}: MobileColorInputProps) => {
  const handleChange = (color: any) => {
    const { r, g, b, a } = color.rgb;
    onChange(`rgba(${r}, ${g}, ${b}, ${a})`);
  };

  return (
    <>
      <ColorPopover>
        <ChromePicker
          color={value}
          onChange={handleChange}
          styles={{
            picker: {
              body: {
                boxShadow: 'none',
              },
            },
          }}
        />
      </ColorPopover>
    </>
  );
};

export default MobileColorInput;
