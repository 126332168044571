import React from 'react';
import { Box } from 'elcano';
import { Product, Variation, Image } from '../picasso';
import Designer from './designer/Designer';

export interface EditorProps {
  product: Product;
  variation: Variation;
  option: string;
  selectVariation: (variation: string) => void;
  selectImage: (image: Image) => void;
  image: Image;
  images: Image[];
}

export const Editor = ({
  product,
  variation,
  option,
  selectVariation,
  selectImage,
  image,
  images,
}: EditorProps) => (
  <Box mt={5} pb="20vh" flexWrap="wrap">
    <Box mb={4} width="100%">
      {images &&
        images.length &&
        images.map((img) => (
          <Designer
            key={img.id}
            id={img.id}
            visible={img.id === image.id}
            image={img}
            images={images}
            product={product}
            variation={variation}
            option={option}
            selectVariation={selectVariation}
            selectImage={selectImage}
          />
        ))}
    </Box>
  </Box>
);
