import Head from 'next/head';
import React from 'react';
import { fontFamilies } from '../data';

export const CustomHead = ({ children }) => {
  if (typeof window !== 'undefined') {
    const families = fontFamilies.map((font) => font.family);
    const WebFontLoader = require('webfontloader'); // eslint-disable-line
    WebFontLoader.load({
      google: {
        families,
      },
    });
  }
  return <Head>{children}</Head>;
};

export default CustomHead;
