import React from 'react';
import { Tiles, Flex, Outline } from 'elcano';
import PropertyGroup from './PropertyGroup';
import { shapes } from '../../data';

export interface ShapePanelProps {
  add: (type: string, object: any) => void;
  editMode?: boolean;
}

const ShapePanel = ({ editMode, add }: ShapePanelProps) => {
  const addShape = (type: string, path?: string) => {
    const shape = {
      strokeWidth: 0,
      fill: 'blue',
      stroke: 'blue',
      radius: 20,
      blendMode: 'normal',
      rotate: 0,
      width: 20,
      height: 20,
      scaleX: 5,
      scaleY: 5,
      type,
      path,
    };
    add(type, shape);
  };

  return (
    <PropertyGroup showIf={!editMode}>
      <Flex>
        <Tiles my={4} columns={[3, 6]}>
          {shapes.map(({ icon, type, path }) => (
            <Outline
              key={type}
              isSelected={false}
              onSelect={() => addShape(type, path)}
            >
              {icon}
            </Outline>
          ))}
        </Tiles>
      </Flex>
    </PropertyGroup>
  );
};

export default ShapePanel;
