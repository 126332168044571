import React, { ChangeEvent } from 'react';
import { Flex, Box, Text, Button, Select, Outline, Link } from 'elcano';

import PropertyGroup from './PropertyGroup';
import { Columns } from './Columns';
import { capitalizeFirstLetter } from '../../utils';
import { Product, Variation, Image } from '../../models';
import { ColorSelect } from '../color-select';

export interface ProductProps {
  product: Product;
  variation: Variation;
  image: Image;
  images: Image[];
  t: (key: string) => string;
  updateAlignment: () => void;
  handleSelect: (varia: string) => void;
  handleImageSelect: (img: Image) => void;
  showDescriptionModal: () => void;
  handleColorSelect: (varia: string) => void;
  onSave?: () => void;
  editMode: boolean;
  isHorizontal: boolean;
}

export const ProductPanel = ({
  product,
  variation,
  image,
  images,
  t,
  updateAlignment,
  handleSelect,
  handleImageSelect,
  showDescriptionModal,
  onSave,
  handleColorSelect,
  editMode,
  isHorizontal,
}: ProductProps) => (
  <PropertyGroup showIf={!editMode}>
    <Flex flexWrap="wrap">
      <>
        {product && product.short_description && (
          <Columns label={t('common:description')} show>
            <Flex my={3}>
              <Flex flexWrap="wrap">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: product.short_description,
                  }}
                />
              </Flex>
            </Flex>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link sx={{ cursor: 'pointer' }} onClick={showDescriptionModal}>
              {t('common:show_more')}
            </Link>
          </Columns>
        )}
      </>
      <>
        {product &&
          product.attributes &&
          !product.colorOptions &&
          product.attributes.map(attribute => (
            <Columns label={attribute.name} show>
              <Flex m={3} my={3} width={1 / 3}>
                <Select
                  width="300px"
                  id="option"
                  name="option"
                  defaultValue={variation && variation.name}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    // eslint-disable-next-line prettier/prettier
                    handleSelect(e.target.value)}
                >
                  {attribute.options.map(op => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Columns>
          ))}
      </>
      <>
        {product && product.colorOptions ? (
          <Columns label={t('common:color')} show>
            <Flex my={3}>
              <ColorSelect product={product} handleSelect={handleColorSelect} />
            </Flex>
          </Columns>
        ) : null}
      </>
      <>
        {images.length > 1 && (
          <Columns label={t('common:type')} show>
            <Flex my={3}>
              {images.map(img => (
                <Outline
                  key={img.id}
                  onSelect={() => handleImageSelect(img)}
                  isSelected={image.id === img.id}
                >
                  <Flex justifyContent="center" width={1}>
                    <Text>{capitalizeFirstLetter(img.type)}</Text>
                  </Flex>
                </Outline>
              ))}
            </Flex>
          </Columns>
        )}
      </>
      <>
        {image.vertical && (
          <Columns label={t('common:orientation')} show>
            <Flex>
              <Button variant="outline" m={1} onClick={updateAlignment}>
                {isHorizontal ? t('common:vertical') : t('common:horizontal')}
              </Button>
            </Flex>
          </Columns>
        )}
        <Columns />
      </>
      <Flex m={3} mt={5} mb={3}>
        <Box>
          <Text>{t('common:disclaimer')}</Text>
        </Box>
      </Flex>

      {onSave && (
        <Flex m={3} mt={5} mb={3}>
          <Box>
            <Button onClick={onSave}>{t('common:save')}</Button>
          </Box>
        </Flex>
      )}
    </Flex>
  </PropertyGroup>
);

export default ProductPanel;
