import { ImageType, Lang, PixaImage } from '../models';

export interface fetchImagesParams {
  page: number;
  term: string;
  type: ImageType;
  lang: Lang;
  featured: boolean;
}

export const fetchImages = async ({
  page,
  term,
  type = 'photo',
  lang = 'en',
  featured = false,
}: fetchImagesParams) => {
  const url = '/api/images';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      page,
      term,
      type,
      lang,
      featured,
    }),
  });

  const { hits }: { hits: PixaImage[] } = await res.json();

  return hits;
};

export default fetchImages;
