import React from 'react';
import { Icon, SVGProps, defaultIconProps } from './Icon';

export const LightBulb = ({
  title = 'LightBulb',
  size = defaultIconProps.size,
  color = defaultIconProps.color,
}: SVGProps) => (
  <Icon title={title}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3H10.5C10.2239 3 10 2.77614 10 2.5V0.5C10 0.223858 10.2239 0 10.5 0H11.5C11.7761 0 12 0.223858 12 0.5V2.5C12 2.77614 11.7761 3 11.5 3ZM5.24458 9.33379C5.98571 6.76996 8.33119 5.00382 11 5C13.6688 5.00382 16.0143 6.76996 16.7554 9.33379C17.4965 11.8976 16.4551 14.6428 14.2 16.07C13.761 16.3422 13.4957 16.8235 13.5 17.34V17.5C13.5 17.7761 13.2761 18 13 18H9C8.72386 18 8.5 17.7761 8.5 17.5V17.34C8.50039 16.8245 8.23606 16.3449 7.8 16.07C5.54485 14.6428 4.50345 11.8976 5.24458 9.33379ZM7 11C7 13.2091 8.79086 15 11 15C12.0609 15 13.0783 14.5786 13.8284 13.8284C14.5786 13.0783 15 12.0609 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11ZM9 20.59C9.0011 20.8523 9.10526 21.1037 9.29 21.29L9.71 21.71C9.89626 21.8947 10.1477 21.9989 10.41 22H11.59C11.8523 21.9989 12.1037 21.8947 12.29 21.71L12.71 21.29C12.8947 21.1037 12.9989 20.8523 13 20.59V20H9V20.59ZM19.13 3.58L18.42 2.87C18.3293 2.77332 18.2026 2.71848 18.07 2.71848C17.9374 2.71848 17.8107 2.77332 17.72 2.87L16.3 4.28C16.1082 4.47781 16.1082 4.79219 16.3 4.99L17 5.7C17.1978 5.89178 17.5122 5.89178 17.71 5.7L19.12 4.28C19.218 4.19066 19.2746 4.06476 19.2765 3.93216C19.2784 3.79957 19.2254 3.67211 19.13 3.58ZM4.28 2.87L5.7 4.28C5.89834 4.47902 5.89834 4.80098 5.7 5L5 5.7C4.80098 5.89834 4.47902 5.89834 4.28 5.7L2.87 4.28C2.77332 4.18927 2.71848 4.06259 2.71848 3.93C2.71848 3.79741 2.77332 3.67073 2.87 3.58L3.58 2.87C3.67073 2.77332 3.79741 2.71848 3.93 2.71848C4.06259 2.71848 4.18927 2.77332 4.28 2.87ZM21.5 10H19.5C19.2239 10 19 10.2239 19 10.5V11.5C19 11.7761 19.2239 12 19.5 12H21.5C21.7761 12 22 11.7761 22 11.5V10.5C22 10.2239 21.7761 10 21.5 10ZM0.5 10H2.5C2.77614 10 3 10.2239 3 10.5V11.5C3 11.7761 2.77614 12 2.5 12H0.5C0.223858 12 0 11.7761 0 11.5V10.5C0 10.2239 0.223858 10 0.5 10Z"
        fill={color}
      />
    </svg>
  </Icon>
);

export default LightBulb;
