import React, { ReactNode } from 'react';
import { Card } from 'elcano';

export interface PropertyGroupProps {
  showIf?: boolean;
  height?: string;
  children: ReactNode;
}

const PropertyGroup = ({
  showIf = true,
  height,
  children,
}: PropertyGroupProps) => {
  if (!showIf) {
    return null;
  }
  return (
    <Card height={height} m={3}>
      {children}
    </Card>
  );
};

export default PropertyGroup;
