import styled from '@emotion/styled';
import { Box, ProgressiveImage, RoundButton } from 'elcano';

export interface StyledDesignerContainerProps {
  visible: boolean;
}

export interface StyledImageProps {
  horizontal: boolean;
}

export interface StyledContainerProps {
  height: number;
  width: number;
}

export interface StyledCanvasContainerProps {
  height: number;
  width: number;
  color?: string;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const StyledCanvasContainer = styled.div<StyledCanvasContainerProps>`
  position: absolute;
  border: 1px dotted ${(props) => props.color || '#000'};
  overflow: hidden;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  touch-action: none;
  width: ${(props) => props.width || 200}px;
  height: ${(props) => props.height || 300}px;
`;

export const StyledDesignerContainer = styled.div<StyledDesignerContainerProps>`
  outline: none;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-wrap: wrap;
`;

export const StyledImage = styled(ProgressiveImage)<StyledImageProps>`
  position: absolute;
  pointer-events: none;
  user-select: none;
  transform: ${(props) =>
    !props.horizontal ? 'rotate(90deg)' : 'rotate(0deg)'};
`;

export const EditorBox = styled(Box)`
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  height: 800px;
`;

export interface StaticRoundButtonContainerProps {
  right?: string;
}

export const StaticRoundButtonContainer = styled.div<StaticRoundButtonContainerProps>`
  position: fixed;
  bottom: 5rem;
  right: ${(props) => props.right || '0px'};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledRoundButton = styled(RoundButton)`
  background: #30c;
`;
