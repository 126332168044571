/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useEffect, MouseEvent } from 'react';
import Router from 'next-translate/Router';
import { Box, Flex, Heading, Slider } from 'elcano';
import { changeDpiDataUrl } from 'changedpi';

import {
  Design,
  DesignerPanels,
  EditorBox,
  isCanvasBlank,
  Product,
  StyledCanvasContainer,
  StyledContainer,
  StyledDesignerContainer,
  StyledImage,
  Variation,
  Image,
  LoadingPage,
} from '../../picasso';
import { useStore, useTranslation } from '../../hooks';
import useFabric from './DesignCanvas';
import StaticButton from '../buttons/StaticButton';
import { Return } from '../Return';
import { uploadDesign } from '../../api';

const CONTAINER_WIDTH = 600;
const INITIAL_SLIDE = 50;
const SLIDE_FACTOR = CONTAINER_WIDTH / INITIAL_SLIDE;
const MOCKUP_CONTAINER_ID = 'mockup-container';

export interface DesignerProps {
  id: string;
  product: Product;
  variation: Variation;
  option: string;
  image: Image;
  selectVariation: (Variation: string) => void;
  selectImage: (image: Image) => void;
  images: Image[];
  visible: boolean;
}

const Designer = ({
  id,
  image,
  product,
  variation,
  option,
  selectVariation,
  selectImage,
  images,
  visible,
}: DesignerProps) => {
  const { newNotification, saveDesign, getDesign } = useStore() as any;
  const { t, lang } = useTranslation();
  const [canvas, setCanvas] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(false);
  const [containerWidth, setContainerWidth] = useState<number>(CONTAINER_WIDTH);
  const [imageWidth, setImageWidth] = useState<number>(image.width);
  const [imageHeight, setImageHeight] = useState<number>(image.height);
  const [maxSlide, setMaxSlide] = useState<number>(null);
  const [defaultSlide, setDefaultSlide] = useState<number>(null);
  const [slideValue, setSlideValue] = useState<number>(null);
  const [isHorizontal, setIsHorizontal] = useState<boolean>(true);
  const [addSlideUp, setAddSlideUp] = useState<boolean>(false);
  const [editSlideUp, setEditSlideUp] = useState<boolean>(false);

  useEffect(() => {
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (screenWidth < CONTAINER_WIDTH) {
      setMobile(true);
      setContainerWidth(300);
      setImageWidth(image.width / 2);
      setImageHeight(image.height / 2);
      setMaxSlide(Math.round(screenWidth / SLIDE_FACTOR));
      const slide = 300 / SLIDE_FACTOR;
      setDefaultSlide(slide);
      setSlideValue(slide);
    } else {
      setMaxSlide(Math.round(screenWidth / 2 / SLIDE_FACTOR));
      const slide = CONTAINER_WIDTH / SLIDE_FACTOR;
      setDefaultSlide(slide);
      setSlideValue(slide);
    }
  }, []);

  useEffect(() => {
    const show = addSlideUp || editSlideUp;
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [addSlideUp, editSlideUp]);

  const scaleObjects = (scale: number) => {
    canvas.calcOffset();
    const objects = canvas.getObjects();
    for (const i in objects) {
      const { scaleX } = objects[i];
      const { scaleY } = objects[i];
      const { left } = objects[i];
      const { top } = objects[i];

      objects[i].scaleX = scaleX * scale;
      objects[i].scaleY = scaleY * scale;
      objects[i].left = left * scale;
      objects[i].top = top * scale;

      objects[i].setCoords();
    }
  };

  const onSlide = ({ target: { value } }) => {
    const updatedContainerWidth = SLIDE_FACTOR * value;
    const imageFactor = CONTAINER_WIDTH / updatedContainerWidth;

    const updatedImageWidth = image.width / imageFactor;
    const updatedImageHeight = image.height / imageFactor;

    setImageWidth(updatedImageWidth);
    setImageHeight(updatedImageHeight);
    setContainerWidth(updatedContainerWidth);

    if (isHorizontal) {
      canvas.setHeight(updatedImageHeight);
      canvas.setWidth(updatedImageWidth);
    } else {
      canvas.setHeight(updatedImageWidth);
      canvas.setWidth(updatedImageHeight);
    }

    scaleObjects(value / slideValue);
    setSlideValue(value);
    canvas.renderAll();
  };

  const saveCanvas = () => {
    const design = canvas.toJSON();
    saveDesign(id, design);
  };

  const zoom = (scale: number) => {
    const width = scale * canvas.getWidth();
    const height = scale * canvas.getHeight();

    canvas.setDimensions({
      width,
      height,
    });

    scaleObjects(scale);

    canvas.renderAll();
  };
  const onComplete = async () => {
    saveCanvas();
    setLoading(true);
    try {
      canvas.discardActiveObject().renderAll();

      const scale = 5;
      zoom(scale);

      const increasedWidth = imageWidth * scale;
      const increasedHeight = imageHeight * scale;

      const designs: Design[] = images
        .filter((i) => {
          const canva = document.getElementById(
            `canvas-${i.id}`,
          ) as HTMLCanvasElement;
          return canva && !isCanvasBlank(canva);
        })
        .map((img) => {
          const designCanvas = document.getElementById(
            `canvas-${img.id}`,
          ) as HTMLCanvasElement;

          const dataUrl = designCanvas.toDataURL('image/png');
          const design = changeDpiDataUrl(dataUrl, 300);

          const { src: imgUrl, type } = images.find(
            (x) => x.id.toString() === img.id.toString(),
          );
          return {
            imgUrl,
            design,
            width: increasedWidth,
            height: increasedHeight,
            containerWidth,
            vertical: !isHorizontal,
            type,
          };
        });

      const designId = await uploadDesign({
        designs,
        product,
        variation,
        option,
      });

      await Router.pushI18n(`/editor/${product.id}/${designId}/quantity`);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      newNotification({
        text: t('common:error_message'),
        type: 'error',
      });
      throw new Error(error);
    }
  };

  const ref = useFabric((fabricCanvas) => {
    const initialCanvas = fabricCanvas;
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    let { width, height } = image;

    if (screenWidth < 600) {
      width = image.width / 2;
      height = image.height / 2;
    }
    initialCanvas.setHeight(height);
    initialCanvas.setWidth(width);
    initialCanvas.selection = false; // disable group selection
    // don't rearrange objects when selected
    initialCanvas.preserveObjectStacking = true;

    const design = getDesign(id);

    if (design) {
      initialCanvas.loadFromJSON(design, () => {
        initialCanvas.renderAll();
      });
    }

    initialCanvas.on('selection:created', (options) => {
      const selection = options.selected[0];
      setSelected(selection);
    });
    initialCanvas.on('selection:cleared', () => {
      setSelected(null);
    });
    initialCanvas.on('selection:updated', (options) => {
      const selection = options.selected[0];
      setSelected(selection);
    });
    setCanvas(initialCanvas);
  });

  const handleColorSelect = (varia: string) => {
    canvas.discardActiveObject().renderAll();
    saveCanvas();
    selectVariation(varia);
  };

  const handleDeselect = (e: MouseEvent<HTMLDivElement>) => {
    // @ts-ignore - Need to access the targets id property
    if (e.target.id === MOCKUP_CONTAINER_ID) {
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  };

  return (
    <>
      {loading ? (
        <LoadingPage t={t} />
      ) : (
        <StyledDesignerContainer visible={visible}>
          <EditorBox width={[1, 1, 1, 1 / 2]}>
            <Box width="100%" p={4}>
              <Flex alignItems="center">
                <Return href="/" />
                <Heading>{product.name}</Heading>
              </Flex>
              <Flex justifyContent="center">
                {defaultSlide ? (
                  <Slider
                    id="size"
                    name="size"
                    defaultValue={defaultSlide}
                    onChange={onSlide}
                    max={maxSlide}
                    min="20"
                    width="60%"
                  />
                ) : null}
              </Flex>
            </Box>
            <StyledContainer width={containerWidth} height={containerWidth}>
              <Box
                width={600}
                id={MOCKUP_CONTAINER_ID}
                onClick={(e) => handleDeselect(e)}
              >
                <StyledImage
                  alt={image.alt}
                  image={image}
                  width={containerWidth}
                  horizontal={isHorizontal}
                />
                <StyledCanvasContainer
                  width={isHorizontal ? imageWidth : imageHeight}
                  height={isHorizontal ? imageHeight : imageWidth}
                  color="#81d742"
                >
                  <canvas id={`canvas-${id}`} ref={ref} />
                </StyledCanvasContainer>
              </Box>
            </StyledContainer>
          </EditorBox>

          <DesignerPanels
            id={id}
            t={t}
            lang={lang}
            canvas={canvas}
            product={product}
            variation={variation}
            image={image}
            selectVariation={selectVariation}
            selectImage={selectImage}
            saveDesign={saveDesign}
            images={images}
            mobile={mobile}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            selected={selected}
            setSelected={setSelected}
            setAddSlideUp={setAddSlideUp}
            setEditSlideUp={setEditSlideUp}
            setIsHorizontal={setIsHorizontal}
            isHorizontal={isHorizontal}
            addSlideUp={addSlideUp}
            editSlideUp={editSlideUp}
            handleColorSelect={handleColorSelect}
          />
          <StaticButton
            loading={loading}
            onClick={() => onComplete()}
            text={t('common:complete_design')}
          />
        </StyledDesignerContainer>
      )}
    </>
  );
};

export default Designer;
