import { Attribute, Image, Variation, Product } from '../models';

// NOTE: alt tags should have the following format:
// design_image|[name]|[type]|[width]|[height]|vertical(optional)
export const filterImages = (images?: Image[]) => {
  const designImages = images?.filter(image =>
    image.alt.includes('design_image')
  );
  return designImages?.map(({ alt, src, id }) => {
    const imgData = alt.split('|');
    const image = {
      id,
      alt: imgData[1],
      type: imgData[2],
      width: imgData[3],
      height: imgData[4],
      vertical: !!imgData[5],
      src,
      preview: src,
    };
    return image;
  });
};

export const mapProduct = (
  id: string,
  {
    name,
    price,
    on_sale: sale,
    images,
    description,
    short_description: shortDescription,
  }: Product,
  variations: Variation[],
  colorOptions: string[],
  attributes: Attribute[]
) => {
  const filteredImages = filterImages(images);
  const product = {
    id,
    name,
    price,
    description,
    short_description: shortDescription,
    sale,
    images: filteredImages,
    image: filteredImages && filteredImages.length ? filteredImages[0] : null,
    variations,
    colorOptions: colorOptions || null,
    attributes: attributes || null,
    attributeName: attributes && attributes[0] ? attributes[0].name : null,
  };
  return product;
};

export const mapDesignedProduct = ({
  variationData,
  variationId,
  variation,
  productData,
  option,
  designs,
}: any) => {
  const optionVariations = variationData.filter((variant: Variation) =>
    variant.attributes.some(attribute => attribute.option === option)
  );

  const sizeAttributes = productData.attributes.find(
    (x: Attribute) => x.name.toLowerCase() === 'size'
  );

  let filteredSizes = null;
  if (sizeAttributes) {
    const { options: sizeOptions } = sizeAttributes;

    filteredSizes = sizeOptions.filter((size: string) =>
      optionVariations.some((variant: Variation) =>
        variant.attributes.some(attribute => attribute.option === size)
      )
    );
  }

  const initialQuantities = {} as any;
  if (filteredSizes) {
    filteredSizes.forEach((size: string) => {
      initialQuantities[size] = 1;
    });
  } else {
    initialQuantities[0] = 1;
  }

  let images = [] as any;
  Object.keys(designs).forEach(x => {
    const { designUrl, mockupUrl } = designs[x];
    const result = [
      {
        src: designUrl,
        preview: designUrl,
      },
      {
        src: mockupUrl,
        preview: mockupUrl,
      },
    ];
    images = images.concat(result);
  });

  const product = {
    ...productData,
    variations: optionVariations,
    variation,
    variationId,
    sizeOptions: filteredSizes,
    designs,
    images,
  };

  return {
    product,
    initialQuantities,
  };
};
