import React from 'react';

export interface IconProps {
  icon: string;
  size?: number;
  active?: boolean;
  onClick?: () => void;
  style?: any;
}

export const Icon = ({
  onClick,
  style,
  icon,
  size = 16,
  active = false,
}: IconProps) => {
  const renderGraphic = () => {
    switch (icon) {
      case 'image':
        return (
          <g>
            <path d="M21 19v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z" />
          </g>
        );
      case 'my-icon':
        return (
          <g>
            <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
          </g>
        );
      case 'another-icon':
        return (
          <g>
            <path d="M7.41 15.41l4.59-4.58 4.59 4.58 1.41-1.41-6-6-6 6z" />
          </g>
        );
      case 'format-bold':
        return (
          <g>
            <path
              d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4h-6.25v14h7.04c2.09
          0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zm-5.6-4.29h3c.83 0 1.5.67 1.5 1.5s-.67
          1.5-1.5 1.5h-3v-3zm3.5 9h-3.5v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"
            />
          </g>
        );
      case 'format-italic':
        return (
          <g>
            <path d="M10 4v3h2.21l-3.42 8h-2.79v3h8v-3h-2.21l3.42-8h2.79v-3z" />
          </g>
        );
      case 'format-underline':
        return (
          <g>
            <path
              d="M12 17c3.31 0 6-2.69 6-6v-8h-2.5v8c0 1.93-1.57 3.5-3.5
          3.5s-3.5-1.57-3.5-3.5v-8h-2.5v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2h-14z"
            />
          </g>
        );
      case 'format-align-left':
        return (
          <g>
            <path
              d="M15 15h-12v2h12v-2zm0-8h-12v2h12v-2zm-12
          6h18v-2h-18v2zm0 8h18v-2h-18v2zm0-18v2h18v-2h-18z"
            />
          </g>
        );
      case 'format-align-center':
        return (
          <g>
            <path d="M7 15v2h10v-2h-10zm-4 6h18v-2h-18v2zm0-8h18v-2h-18v2zm4-6v2h10v-2h-10zm-4-4v2h18v-2h-18z" />
          </g>
        );
      case 'format-align-right':
        return (
          <g>
            <path d="M3 21h18v-2h-18v2zm6-4h12v-2h-12v2zm-6-4h18v-2h-18v2zm6-4h12v-2h-12v2zm-6-6v2h18v-2h-18z" />
          </g>
        );
      case 'add-box':
        return (
          <g>
            <path
              d="M19 3h-14c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-2
          10h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z"
            />
          </g>
        );
      case 'add':
        return (
          <g>
            <path d="M19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" />
          </g>
        );
      case 'text-format':
        return (
          <g>
            <path
              d="M5 17v2h14v-2h-14zm4.5-4.2h5l.9 2.2h2.1l-4.75-11h-1.5l-4.75
          11h2.1l.9-2.2zm2.5-6.82l1.87 5.02h-3.74l1.87-5.02z"
            />
          </g>
        );
      case 'text':
        return (
          <g>
            <path
              d="M5 17v2h14v-2h-14zm4.5-4.2h5l.9 2.2h2.1l-4.75-11h-1.5l-4.75
           11h2.1l.9-2.2zm2.5-6.82l1.87 5.02h-3.74l1.87-5.02z"
            />
          </g>
        );
      case 'rect':
        return <rect width={14} height={14} x={4} y={5} rx={3} ry={3} />;
      case 'circle':
        return <circle r={8} cx={11} cy={12} />;
      case 'polygon':
        return (
          <g transform="scale(0.034) translate(100 100)">
            <path
              d="M477.043,219.205L378.575,48.677c-7.974-13.802-22.683-22.292-38.607-22.292H143.041c-15.923,
                    0-30.628,8.49-38.608,22.292 L5.971,219.205c-7.961,13.801-7.961,30.785,0,44.588l98.462,
                    170.543c7.98,13.802,22.685,22.293,38.608,22.293h196.926 c15.925,0,30.634-8.491,
                    38.607-22.293l98.469-170.543C485.003,249.99,485.003,233.006,477.043,219.205z"
            />
          </g>
        );
      case 'triangle':
        return (
          <g transform="scale(0.10) translate(55 35)">
            <path d="M 10,150 L 70,10 L 130,150 z" />
          </g>
        );
      case 'rotate':
        return (
          <g>
            <path
              d="M18.4 10.6c-1.85-1.61-4.25-2.6-6.9-2.6-4.65 0-8.58 3.03-9.96
           7.22l2.36.78c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88l-3.62
           3.62h9v-9l-3.6 3.6z"
            />
          </g>
        );
      case 'send-to-back':
        return (
          <g>
            <rect
              x={7}
              y={7}
              width={13}
              height={13}
              fill="#ababab"
              strokeWidth={1}
              stroke="black"
            />
            <rect
              x={1}
              y={1}
              width={13}
              height={13}
              fill="white"
              stroke="black"
              strokeWidth={1}
            />
          </g>
        );
      case 'bring-to-front':
        return (
          <g>
            <rect
              x={7}
              y={7}
              width={13}
              height={13}
              fill="white"
              strokeWidth={1}
              stroke="black"
            />
            <rect
              x={1}
              y={1}
              width={13}
              height={13}
              fill="#ababab"
              stroke="black"
              strokeWidth={1}
            />
          </g>
        );
      case 'times':
        return (
          <g transform="scale(1.5) translate(2 2)">
            <path
              d="M11.8499 10.4399C11.9445 10.5337 11.9978 10.6615 11.9978 10.7949C11.9978 10.9282 11.9445 11.056 11.8499 11.1499L11.1499 11.8499C11.056 11.9445 10.9282 11.9978 10.7949 11.9978C10.6615 11.9978 10.5337 11.9445 10.4399 11.8499L5.99985 7.40985L1.55985 11.8499C1.46597 11.9445 1.33817 11.9978 1.20485 11.9978C1.07153 11.9978 0.943736 11.9445 0.849852 11.8499L0.149852 11.1499C0.055196 11.056 0.00195312 10.9282 0.00195312 10.7949C0.00195312 10.6615 0.055196 10.5337 0.149852 10.4399L4.58985 5.99985L0.149852 1.55985C0.055196 1.46597 0.00195313 1.33817 0.00195313 1.20485C0.00195313 1.07153 0.055196 0.943736 0.149852 0.849852L0.849852 0.149852C0.943736 0.055196 1.07153 0.00195313 1.20485 0.00195313C1.33817 0.00195313 1.46597 0.055196 1.55985 0.149852L5.99985 4.58985L10.4399 0.149852C10.5337 0.055196 10.6615 0.00195312 10.7949 0.00195312C10.9282 0.00195312 11.056 0.055196 11.1499 0.149852L11.8499 0.849852C11.9445 0.943736 11.9978 1.07153 11.9978 1.20485C11.9978 1.33817 11.9445 1.46597 11.8499 1.55985L7.40985 5.99985L11.8499 10.4399Z"
              fill="black"
            />
          </g>
        );
      case 'times-white':
        return (
          <g transform="scale(1.5) translate(2 2)">
            <path
              d="M11.8499 10.4399C11.9445 10.5337 11.9978 10.6615 11.9978 10.7949C11.9978 10.9282 11.9445 11.056 11.8499 11.1499L11.1499 11.8499C11.056 11.9445 10.9282 11.9978 10.7949 11.9978C10.6615 11.9978 10.5337 11.9445 10.4399 11.8499L5.99985 7.40985L1.55985 11.8499C1.46597 11.9445 1.33817 11.9978 1.20485 11.9978C1.07153 11.9978 0.943736 11.9445 0.849852 11.8499L0.149852 11.1499C0.055196 11.056 0.00195312 10.9282 0.00195312 10.7949C0.00195312 10.6615 0.055196 10.5337 0.149852 10.4399L4.58985 5.99985L0.149852 1.55985C0.055196 1.46597 0.00195313 1.33817 0.00195313 1.20485C0.00195313 1.07153 0.055196 0.943736 0.149852 0.849852L0.849852 0.149852C0.943736 0.055196 1.07153 0.00195313 1.20485 0.00195313C1.33817 0.00195313 1.46597 0.055196 1.55985 0.149852L5.99985 4.58985L10.4399 0.149852C10.5337 0.055196 10.6615 0.00195312 10.7949 0.00195312C10.9282 0.00195312 11.056 0.055196 11.1499 0.149852L11.8499 0.849852C11.9445 0.943736 11.9978 1.07153 11.9978 1.20485C11.9978 1.33817 11.9445 1.46597 11.8499 1.55985L7.40985 5.99985L11.8499 10.4399Z"
              fill="white"
            />
          </g>
        );
      case 'cart':
        return (
          <g transform="scale(0.034) translate(100 100)">
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
            />
          </g>
        );
      case 'pencil':
        return (
          <g transform="scale(1.1) translate(2 2)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.9024 0.439229L17.5609 1.0979C17.8416 1.37837 17.9995 1.75888 17.9999 2.15577V2.91424C18.0049 3.31803 17.8466 3.70672 17.5609 3.99206L15.7151 5.8084C15.6215 5.90287 15.4939 5.956 15.3609 5.956C15.2279 5.956 15.1004 5.90287 15.0067 5.8084L12.2032 2.96414C12.013 2.77011 12.013 2.45958 12.2032 2.26555L14.009 0.439229C14.2943 0.153471 14.6829 -0.00488331 15.0866 0.000114826H15.8448C16.2416 0.000464225 16.622 0.158411 16.9024 0.439229ZM13.8195 7.68462C14.0096 7.49059 14.0096 7.18006 13.8195 6.98603L10.966 4.1717C10.8723 4.07724 10.7448 4.0241 10.6118 4.0241C10.4788 4.0241 10.3513 4.07724 10.2576 4.1717L2.20608 12.2255C2.01142 12.4184 1.85859 12.6494 1.75711 12.9041L0.0410455 17.2354C-0.0561655 17.4602 0.0241544 17.7224 0.230611 17.8541C0.372012 17.9953 0.584015 18.0385 0.769376 17.9639L5.09945 16.2074C5.35403 16.1059 5.58498 15.953 5.7779 15.7583L13.8195 7.68462Z"
              fill="black"
            />
          </g>
        );
      case 'plus':
        return (
          <g transform="scale(1.5, 1.5) translate(-4, -4)">
            <path d="M19 11.5V12.5C19 12.7761 18.7761 13 18.5 13H13V18.5C13 18.7761 12.7761 19 12.5 19H11.5C11.2239 19 11 18.7761 11 18.5V13H5.5C5.22386 13 5 12.7761 5 12.5V11.5C5 11.2239 5.22386 11 5.5 11H11V5.5C11 5.22386 11.2239 5 11.5 5H12.5C12.7761 5 13 5.22386 13 5.5V11H18.5C18.7761 11 19 11.2239 19 11.5Z" />
          </g>
        );
      case 'plus-white':
        return (
          <g transform="scale(1.5, 1.5) translate(-4, -4)">
            <path
              d="M19 11.5V12.5C19 12.7761 18.7761 13 18.5 13H13V18.5C13 18.7761 12.7761 19 12.5 19H11.5C11.2239 19 11 18.7761 11 18.5V13H5.5C5.22386 13 5 12.7761 5 12.5V11.5C5 11.2239 5.22386 11 5.5 11H11V5.5C11 5.22386 11.2239 5 11.5 5H12.5C12.7761 5 13 5.22386 13 5.5V11H18.5C18.7761 11 19 11.2239 19 11.5Z"
              fill="white"
            />
          </g>
        );
      case 'check':
        return (
          <g transform="translate(2, 5)">
            <path d="M16.85 2.24999L6.24997 12.85C6.15609 12.9447 6.02829 12.9979 5.89497 12.9979C5.76166 12.9979 5.63386 12.9447 5.53997 12.85L0.149974 7.45999C0.055318 7.36611 0.0020752 7.23831 0.0020752 7.10499C0.0020752 6.97168 0.055318 6.84388 0.149974 6.74999L0.849974 6.04999C0.943858 5.95534 1.07166 5.9021 1.20497 5.9021C1.33829 5.9021 1.46609 5.95534 1.55997 6.04999L5.88997 10.38L15.44 0.829995C15.6378 0.638211 15.9522 0.638211 16.15 0.829995L16.85 1.53999C16.9446 1.63388 16.9979 1.76168 16.9979 1.89499C16.9979 2.02831 16.9446 2.15611 16.85 2.24999Z" />
          </g>
        );
      default:
        return <circle r={8} cx={11} cy={12} />;
    }
  };

  const styles = {
    fill: active ? 'black' : '#b5b5b5',
    verticalAlign: 'middle',
    width: size,
    height: size,
    cursor: 'pointer',
  };

  return (
    <svg
      viewBox="0 0 24 24"
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
      style={{ ...styles, ...style }}
    >
      {renderGraphic()}
    </svg>
  );
};
