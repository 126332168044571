import { Design, Product, Variation } from '../picasso';

export interface uploadDesignParams {
  designs: Design[];
  product: Product;
  variation: Variation;
  option: string;
}

export const uploadDesign = async ({
  designs,
  product,
  variation,
  option,
}: uploadDesignParams) => {
  const url = process.env.SVG_EDITOR_URL;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      designs,
      productId: product.id,
      attributeName: product.attributeName,
      option,
      variationId: variation && variation.variationId,
    }),
  });

  const designId = (await res.json()) as string;

  return designId;
};

export default uploadDesign;
