import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

export interface LoaderProps {
  color?: string;
  size?: string;
  thickness?: string;
}

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoaderStyle = styled.div<LoaderProps>`
  border: ${(props) => props.thickness || '0.2em'} solid
    ${(props) => props.color || 'rgba(0, 0, 0, 0.1)'};
  border-top: ${(props) => props.thickness || '0.2em'} solid #ffff;
  border-radius: 50%;
  width: ${(props) => props.size || '0.8rem'};
  height: ${(props) => props.size || '0.8rem'};
  animation: ${spin} 0.8s linear infinite;
`;

export const Loader = ({ size, color, thickness }: LoaderProps) => {
  return <LoaderStyle size={size} color={color} thickness={thickness} />;
};

export default Loader;
