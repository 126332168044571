/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Flex, Box } from 'elcano';
import { TextPanel } from './TextPanel';
import { ActionPanel } from './ActionPanel';
import StylePanel from './StylePanel';
import SizePanel from './SizePanel';
import { UploadPanel } from './UploadPanel';
import ShapePanel from './ShapePanel';
import { ImagePanel } from './ImagePanel';
import { ProductPanel } from './ProductPanel';
import { Product, Tool, Variation, Image, Lang } from '../../models';

const panels = [
  { Component: ProductPanel, id: 'product' },
  { Component: TextPanel, id: 'text' },
  { Component: UploadPanel, id: 'upload' },
  { Component: ShapePanel, id: 'shape' },
  { Component: ImagePanel, id: 'vector' },
  { Component: ImagePanel, id: 'photo' },
  { Component: StylePanel, id: 'style' },
  { Component: SizePanel, id: 'size' },
  { Component: ActionPanel, id: 'action' },
];

export interface PanelListProps {
  id?: string;
  t: (key: string) => string;
  lang: Lang;
  product: Product;
  variation: Variation;
  image: Image;
  images: Image[];
  editMode: boolean;
  selectedTool: Tool;
  add: (type: string, object: any) => void;
  onChange: (key: string, value: any) => void;
  object: any;
  onArrange: (e: any) => void;
  onRemove: () => void;
  onClearAll: () => void;
  onCopy: () => void;
  alignVertically: () => void;
  alignHorizontally: () => void;
  updateAlignment: () => void;
  handleSelect: (varia: string) => void;
  handleImageSelect: (img: Image) => void;
  showDescriptionModal: () => void;
  handleColorSelect: (varia: string) => void;
  onSave?: () => void;
  isHorizontal: boolean;
}

export const PanelList = ({
  editMode,
  selectedTool,
  ...rest
}: PanelListProps) => {
  const selected = selectedTool || 'product';
  return (
    <Flex width={1} css={{ marginBottom: 80 }}>
      {!editMode ? (
        <Box width={1}>
          <Box>
            {selected === 'product' && (
              <ProductPanel {...rest} editMode={false} />
            )}
          </Box>
          <Box>{selected === 'text' && <TextPanel {...rest} />}</Box>
          <Box>{selected === 'upload' && <UploadPanel {...rest} />}</Box>
          <Box>{selected === 'shape' && <ShapePanel {...rest} />}</Box>
          <Box>
            {selected === 'elements' && <ImagePanel type="vector" {...rest} />}
          </Box>
          <Box>
            {selected === 'photo' && <ImagePanel type="photo" {...rest} />}
          </Box>
        </Box>
      ) : (
        <Box width={1}>
          {panels.map(Panel => (
            <Panel.Component key={Panel.id} {...rest} editMode />
          ))}
        </Box>
      )}
    </Flex>
  );
};

export default PanelList;
