import React from 'react';
import { Flex, Button } from 'elcano';

import PropertyGroup from './PropertyGroup';
import { Icon } from '../Icon';
import { Columns } from './Columns';
import { Arrange } from '../../models';

export interface SizePanelProps {
  object: any;
  onChange: (key: string, value: string) => void;
  onArrange: (arrangeType: Arrange) => void;
  t: (key: string) => string;
}

const SizePanel = ({ onArrange, t }: SizePanelProps) => (
  <PropertyGroup>
    <Flex flexWrap="wrap">
      {/* {_.has(object, 'width', 'height') && (
          <Columns label="Size" show>
            <Column show={_.has(object, 'width')} label="width">
              <Input
                value={object.width}
                onChange={e => onChange('width', e.target.value)}
              />
            </Column>
            <Column show={_.has(object, 'height')} label="height">
              <Input
                value={object.height}
                onChange={e => onChange('height', e.target.value)}
              />
            </Column>
          </Columns>
        )}
        <Columns label="Position" show>
          <Column show={_.has(object, 'x')} label="left">
            <Input
              value={object.x}
              onChange={e => onChange('x', e.target.value)}
            />
          </Column>
          <Column show={_.has(object, 'y')} label="top">
            <Input
              value={object.y}
              onChange={e => onChange('y', e.target.value)}
            />
          </Column>
        </Columns> */}
      {/* {_.has(object, 'rotate') && (
          <Columns label="Rotation" show>
            <Column>
              <Input
                value={object.rotate}
                onChange={(e) => onChange('rotate', e.target.value)}
              />
            </Column>
          </Columns>
        )} */}
      <Columns label={t('common:arrange')} show>
        <Flex my={3}>
          <Button variant="round" onClick={() => onArrange('back')}>
            <Icon icon="send-to-back" />
          </Button>
          <Button variant="round" onClick={() => onArrange('front')}>
            <Icon icon="bring-to-front" />
          </Button>
        </Flex>
      </Columns>
    </Flex>
  </PropertyGroup>
);

export default SizePanel;
