import React from 'react';
import { Flex, Box, Button, Heading } from 'elcano';
import Router from 'next-translate/Router';
import { Product } from '../picasso';
import { Return } from './Return';
import BundleQuantities from './BundleQuantities';
import StaticButton from './buttons/StaticButton';

export interface QuantitiesProps {
  id: string;
  product: Product;
  t: (key: string) => string;
  add: () => void;
  handleOptions: (key: any, value: any) => void;
  complete: boolean;
  loading: boolean;
  optionQuantities: any;
}

export const Quantities = ({
  product,
  id,
  t,
  add,
  complete,
  handleOptions,
  optionQuantities,
  loading,
}: QuantitiesProps) => (
  <Box mt={5} width="100%" p={4}>
    <Flex alignItems="center">
      <Return href={`/editor/${id}`} />
      <Heading>{t('common:quantity')}</Heading>
    </Flex>

    <Flex m={4} flexWrap="wrap">
      <BundleQuantities
        products={[product]}
        handleOptions={handleOptions}
        optionQuantities={optionQuantities}
      />
    </Flex>

    <StaticButton
      text={t('common:add_to_cart')}
      loading={loading}
      onClick={() => add()}
    />

    <StaticButton
      zIndex={2}
      loading={false}
      onClick={() => {
        window.location.href = 'https://www.kuvatehdas.fi/fi/ostoskori/';
      }}
      text={t('common:go_to_cart')}
      show={complete}
    >
      <Button
        m={1}
        width={[1, 1, 1 / 4]}
        variant="outline"
        onClick={() => Router.pushI18n('/')}
      >
        {t('common:continue_shopping')}
      </Button>
      <Box width={30} />
    </StaticButton>
  </Box>
);
