import React, { ChangeEvent } from 'react';
import { Flex } from 'elcano';
import PropertyGroup from './PropertyGroup';
import { Columns } from './Columns';
import ColorInput from './ColorInput';
import { MobileColorInput } from './MobileColorInput';

export interface StylePanelProps {
  object: any;
  onChange: (type: string, e: any) => void;
  t: (key: string) => string;
  isMobile?: boolean;
}

// const modes = [
//   'normal',
//   'multiply',
//   'screen',
//   'overlay',
//   'darken',
//   'lighten',
//   'color-dodge',
//   'color-burn',
//   'hard-light',
//   'soft-light',
//   'difference',
//   'exclusion',
//   'hue',
//   'saturation',
//   'color',
//   'luminosity',
// ];

const StylePanel = ({
  object,
  onChange,
  t,
  isMobile = false,
}: StylePanelProps) => (
  <>
    {!isMobile ? (
      <PropertyGroup>
        <Flex flexWrap="wrap">
          <Columns label={t('common:fill')} show={object && object.fill}>
            <Flex my={3}>
              <ColorInput
                value={object?.fill}
                onChange={(e: ChangeEvent) => onChange('fill', e)}
              />
            </Flex>
          </Columns>
        </Flex>
      </PropertyGroup>
    ) : (
      <Flex justifyContent="center">
        <MobileColorInput
          value={object?.fill}
          onChange={(e: ChangeEvent) => onChange('fill', e)}
        />
      </Flex>
    )}

    {/* <Columns label="Stroke" show={_.has(object, 'stroke')}>
        <Column>
          <Flex mr={4}>
            <ColorInput
              value={object.stroke}
              onChange={(e) => onChange('stroke', e)}
            />
          </Flex>
        </Column>
        <Column label="width">
          <Input
            onChange={(e) => onChange('strokeWidth', e.target.value)}
            value={object.strokeWidth}
          />
        </Column>
        <Column showIf={_.has(object, 'radius')} label="radius">
          <Input
            onChange={(e) => onChange('radius', e.target.value)}
            value={object.radius}
          />
        </Column>
      </Columns> */}
    {/* <Columns label="Blending" show>
          <Column>
            <Select
              value={object.blendMode}
              onChange={e => onChange('blendMode', e.target.value)}
            >
              &gt;
              {modes.map(mode => (
                <option key={mode} value={mode}>
                  {mode}
                </option>
              ))}
            </Select>
          </Column>
        </Columns> */}
  </>
);

export default StylePanel;
