import React from 'react';
import Image from 'next/image';
import { Flex, Box, Heading, Text } from 'elcano';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useTranslation } from '../hooks';

const ProductCardContainer = styled(motion.div)`
  cursor: pointer;
  display: flex;
  min-width: 0;
`;

const ProductCard = styled(Flex)`
  cursor: pointer;
`;

const ImageContainer = styled(Flex)`
  position: relative;
`;

export const Product = ({
  product: { id, price, name, image },
  onClick,
  showPrice,
}) => {
  const { t } = useTranslation();
  return (
    <ProductCardContainer whileHover={{ scale: 1.02 }}>
      <ProductCard
        height={350}
        onClick={() => onClick(id)}
        variant="product"
        flexWrap="wrap"
        width={1}
      >
        <ImageContainer justifyContent="center" width={1} height="250px">
          <Image src={image?.src} alt={name} layout="fill" objectFit="cover" />
        </ImageContainer>
        <Box textAlign="left" width={1} mx={3}>
          {name?.length > 35 ? (
            <Heading>{name.slice(0, 35).concat('...')}</Heading>
          ) : (
            <Heading>{name}</Heading>
          )}
          <div>{showPrice && <Text>{t('common:price', { price })}</Text>}</div>
        </Box>
      </ProductCard>
    </ProductCardContainer>
  );
};

export default Product;
