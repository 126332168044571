import React from 'react';
import Image from 'next/image';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import Link from 'next-translate/Link';
import { Box, Flex, Text } from 'elcano';
import { useStore, useTranslation, useRouter } from '../hooks';
import { allLanguages } from '../i18n.json';
import { Icon } from './designer/Icon';
import { NotificationType } from '../models';

const getBackgroundColor = ({ type }) => {
  if (type === 'success') {
    return '#2ddad3';
  }
  return '#f46d6d';
};

export interface NotificationProps {
  type: NotificationType;
}

const Notification = styled(motion.div)<NotificationProps>`
  background-color: ${(props) => getBackgroundColor(props)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 2em;
  margin-top: 3.5em;
  z-index: 9998;
`;

const StyledLink = styled(Flex)`
  color: black;
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
`;

const LogoWrapper = styled(Flex)`
  cursor: pointer;
`;

export const Navbar = () => {
  const { notification } = useStore() as any;
  const { lang } = useTranslation();
  const { asPath } = useRouter();

  const path = asPath
    .split('/')
    .filter((el) => !allLanguages.includes(el))
    .join('/');

  return (
    <Flex variant="navContainer">
      <Flex
        justifyContent="space-between"
        variant="nav"
        px={2}
        color="black"
        bg="white"
      >
        <Flex>
          <Link href="/">
            <LogoWrapper>
              <Image
                src="https://www.kuvatehdas.fi/wp-content/uploads/2018/02/Kuvatehdas.png"
                alt="logo"
                width={150}
                height={40}
              />
            </LogoWrapper>
          </Link>
          <Flex alignItems="center" px={3}>
            <Box
              sx={{
                display: 'flex',
                color: 'white',
                bg: 'primary',
                borderRadius: 'small',
                alignItems: 'center',
                height: '50%',
                backgroundColor: 'secondary',
                p: 2,
              }}
            >
              Beta
            </Box>
          </Flex>
          {/* <Flex alignItems="center" px={3}>
            <Link href="/">
              <StyledLink>{t('common:all_products')}</StyledLink>
            </Link>
          </Flex> */}
        </Flex>
        <Flex>
          <Flex alignItems="center" px={3}>
            <StyledLink>
              <Icon
                icon="cart"
                active
                onClick={() => {
                  window.location.href =
                    'https://www.kuvatehdas.fi/fi/ostoskori/';
                }}
              />
            </StyledLink>
          </Flex>
          <Flex>
            {allLanguages.map((lng) => {
              if (lng === lang) return null;

              return (
                <Link href={path || '/'} lang={lng} key={lng}>
                  <StyledLink alignItems="center" mr={3}>
                    {lng}
                  </StyledLink>
                </Link>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Flex />
      <Flex variant="notificationContainer">
        <AnimatePresence>
          {notification && (
            <Notification
              type={notification.type}
              initial={{ opacity: 0, y: '-100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-100%' }}
            >
              <Text>{notification.text}</Text>
            </Notification>
          )}
        </AnimatePresence>
      </Flex>
    </Flex>
  );
};

export default Navbar;
