import api from '../utils/initWoo';

export const getVariations = async (id: string) => {
  const url = `products/${id}/variations`;
  const variationResponse = await api.get(url, {
    per_page: 100,
  });
  const { data } = variationResponse;

  return data;
};

export default getVariations;
