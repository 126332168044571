// @ts-nocheck
export * from './product';

export const isCanvasBlank = (canvas: HTMLCanvasElement) => {
  if (!canvas || !canvas.width || !canvas.height) {
    return false;
  }
  return !canvas
    .getContext('2d')
    .getImageData(0, 0, canvas.width, canvas.height)
    .data.some(channel => channel !== 0);
};

export default isCanvasBlank;

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getImgUrl = (url: string): string => {
  return `/api/proxy?filename=${url}`;
};

const umlautMap = {
  Ü: 'U',
  Ä: 'A',
  Ö: 'O',
  ü: 'u',
  ä: 'a',
  ö: 'o',
  ß: 'ss',
  å: 'a',
};

export const replaceUmlaute = (str: string) => {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, a => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(
      new RegExp(`[${Object.keys(umlautMap).join('|')}]`, 'g'),
      a => umlautMap[a]
    );
};
