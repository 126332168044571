import React, { useCallback, useState } from 'react';
import { Flex, Box, Button, Heading, StaticButton } from 'elcano';
import styled from '@emotion/styled';

import { useDropzone } from 'react-dropzone';
import PropertyGroup from './PropertyGroup';

import { Columns } from './Columns';

interface DropboxEventProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
}

const getColor = ({
  isDragAccept,
  isDragActive,
  isDragReject,
}: DropboxEventProps) => {
  if (isDragAccept) {
    return '#00e676';
  }
  if (isDragReject) {
    return '#ff1744';
  }
  if (isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Dropbox = styled(Flex)`
  min-height: 300px;
  flex-direction: column;
  padding: 20px;
  border-width: 2px;
  border-radius: 7px;
  border-color: ${(props: DropboxEventProps) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const DroppedImg = styled.img`
  max-width: 100%;
`;

export interface UploadPanelProps {
  add: (type: string, object: any) => void;
  editMode?: boolean;
  t: (key: string) => string;
  isMobile?: boolean;
}

export const UploadPanel = ({
  add,
  editMode,
  t,
  isMobile = false,
}: UploadPanelProps) => {
  const [image, updateImage] = useState('');

  const addImage = () => {
    const imageObject = {
      href: image,
      width: 100,
      height: 100,
    };

    add('image', imageObject);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      const fr = new FileReader();

      const setImage = ({ target }: ProgressEvent<FileReader>) => {
        if (target?.result) {
          updateImage(target.result as string);
        }
      };
      fr.onload = setImage;
      fr.readAsDataURL(file);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });
  const rootProps: DropboxEventProps = {
    isDragActive,
    isDragAccept,
    isDragReject,
  };

  const getDisplay = (dropboxProps: DropboxEventProps) => {
    if (image) {
      return <DroppedImg src={image} alt="img" />;
    }
    if (dropboxProps.isDragActive) {
      return <Heading>{t('common:drop_image_here')}</Heading>;
    }
    return <Heading>{t('common:drop_image')}</Heading>;
  };

  return (
    <PropertyGroup showIf={!editMode}>
      {isMobile && !!image && (
        <Flex width={1}>
          <Button
            m={1}
            width={[1, 1, 1 / 4]}
            variant="outline"
            onClick={() => updateImage('')}
          >
            {t('common:cancel')}
          </Button>
          <Button m={1} width={[1, 1, 1 / 4]} onClick={() => addImage()}>
            {t('common:add_image')}
          </Button>
        </Flex>
      )}
      <Columns label={t('common:image')} show>
        <Dropbox m={1} {...getRootProps(rootProps)}>
          <input {...getInputProps()} />
          <div>{getDisplay(rootProps)}</div>
        </Dropbox>
      </Columns>
      {!isMobile && (
        <StaticButton
          zIndex={2}
          loading={false}
          onClick={() => addImage()}
          text={t('common:add_image')}
          show={!!image}
        >
          <Button
            m={1}
            width={[1, 1, 1 / 4]}
            variant="outline"
            onClick={() => updateImage('')}
          >
            {t('common:cancel')}
          </Button>
          <Box width={30} />
        </StaticButton>
      )}
    </PropertyGroup>
  );
};

export default UploadPanel;
