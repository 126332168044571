import React from 'react';
import styled from '@emotion/styled';
import Router from 'next-translate/Router';
import RoundButton from './buttons/RoundButton';
import { Icon } from './designer/Icon';

export interface ReturnProps {
  href: string;
}

const StyledRoundButton = styled(RoundButton)`
  transform: rotate(-90deg);
  margin-left: 0;
`;

export const Return = ({ href }: ReturnProps) => (
  <StyledRoundButton onClick={() => Router.pushI18n(href)}>
    <Icon icon="another-icon" active size={30} />
  </StyledRoundButton>
);

export default Return;
