import React from 'react';
import { Flex, Box } from 'elcano';
import { TextPanel } from './TextPanel';
import { UploadPanel } from './UploadPanel';
import ShapePanel from './ShapePanel';
import { ImagePanel } from './ImagePanel';
import { Lang, Tool } from '../../models';

export interface AddPanelListProps {
  selectedTool: Tool;
  add: (type: string, object: any) => void;
  t: (key: string) => string;
  lang: Lang;
  isMobile?: boolean;
}

export const AddPanelList = ({ selectedTool, ...rest }: AddPanelListProps) => (
  <Flex width={1}>
    <Box width={1}>
      <Box>{selectedTool === 'text' && <TextPanel {...rest} />}</Box>
      <Box>{selectedTool === 'upload' && <UploadPanel {...rest} />}</Box>
      <Box>{selectedTool === 'shape' && <ShapePanel {...rest} />}</Box>
      <Box>
        {selectedTool === 'elements' && <ImagePanel type="vector" {...rest} />}
      </Box>
      <Box>
        {selectedTool === 'photo' && <ImagePanel type="photo" {...rest} />}
      </Box>
    </Box>
  </Flex>
);

export default AddPanelList;
