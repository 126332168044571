import React from 'react';
import { Flex, Heading, Spinner } from 'elcano';

export interface LoadingPageProps {
  t: (key: string) => string;
}

export const LoadingPage = ({ t }: LoadingPageProps) => (
  <Flex
    mt={6}
    flexWrap="wrap"
    height="100%"
    justifyContent="center"
    textAlign="center"
  >
    <Flex my={4} justifyContent="center" width="100%">
      <Spinner size="5rem" color="#000" thickness="0.4em" />
    </Flex>
    <Flex my={4} justifyContent="center" width="100%">
      <Heading>{t('common:loading_message')}</Heading>
    </Flex>
  </Flex>
);

export default LoadingPage;
