/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { GalleryImage as GalleryImageInterface } from '../models';

const cont: any = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
};

export interface GalleryImageProps {
  photo: GalleryImageInterface;
  margin: string;
  direction: 'column' | 'row';
  top?: number;
  left?: number;
  onClick: (src: string) => void;
  alt?: string;
}

export const GalleryImage = ({
  photo,
  margin,
  direction,
  top,
  left,
  onClick,
  alt = '',
}: GalleryImageProps) => {
  if (direction === 'column') {
    cont.position = 'absolute';
    cont.left = left;
    cont.top = top;
  }

  return (
    <div style={{ margin, height: photo.height, width: photo.width, ...cont }}>
      <img {...photo} onClick={() => onClick(photo.src)} alt={alt} />
    </div>
  );
};

export default GalleryImage;
