import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import styled from '@emotion/styled';

export interface ColorInputProps {
  onChange: any;
  value: any;
}

export interface ColorInputState {
  show: boolean;
}

const ColorInput = styled.a`
  width: 18;
  height: 18;
  border-radius: '50%';
  display: 'inline-block';
  background: 'white';
  margin-right: 3;
`;

const Color = styled.span`
  background-color: ${props => props.color};
  margin-left: 2px;
  margin-top: 2px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000;
`;

const ColorCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ColorPopover = styled.div`
  position: absolute;
  margin-top: 8px;
  z-index: 999999;
`;

export class ColorInputComponent extends Component<
  ColorInputProps,
  ColorInputState
> {
  constructor(props: ColorInputProps) {
    super(props);

    this.state = {
      show: false,
    };
  }

  toggleVisibility = (event: any) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    const { show } = this.state;
    this.setState({
      show: !show,
    });
  };

  handleChange = (color: any) => {
    const { r, g, b, a } = color.rgb;
    const { onChange } = this.props;
    onChange(`rgba(${r}, ${g}, ${b}, ${a})`);
  };

  handleClose = (event: any) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    this.setState({
      show: false,
    });
  };

  render() {
    const { show } = this.state;
    const { value } = this.props;

    return (
      <div>
        <ColorInput href="#" onClick={e => this.toggleVisibility(e)}>
          <Color color={value} />
        </ColorInput>
        {show && (
          <ColorPopover>
            <ColorCover onClick={this.handleClose} />
            <SketchPicker color={value} onChange={this.handleChange} />
          </ColorPopover>
        )}
      </div>
    );
  }
}

export default ColorInputComponent;
