import React from 'react';
import { Button, Flex, RoundButton } from 'elcano';
import styled from '@emotion/styled';

import { Icon } from '../Icon';
import PropertyGroup from './PropertyGroup';
import { Columns } from './Columns';

export interface ActionPanelProps {
  onRemove: () => void;
  onClearAll: () => void;
  onCopy: () => void;
  alignVertically: () => void;
  alignHorizontally: () => void;
  t: (key: string) => string;
}

const RotatedButton = styled(RoundButton)`
  transform: rotate(90deg);
`;

export const ActionPanel = ({
  onRemove,
  onClearAll,
  onCopy,
  alignVertically,
  alignHorizontally,
  t,
}: ActionPanelProps) => {
  return (
    <PropertyGroup>
      <Flex justifyContent="start">
        <Columns label={t('common:actions')} show>
          <Flex alignItems="center" flexWrap="wrap">
            <RotatedButton onClick={() => alignVertically()}>
              <Icon icon="format-align-center" />
            </RotatedButton>
            <RoundButton onClick={() => alignHorizontally()}>
              <Icon icon="format-align-center" />
            </RoundButton>
            <RoundButton onClick={() => onCopy()}>
              <Icon icon="bring-to-front" />
            </RoundButton>
            <RoundButton backgroundColor="#FB6161" onClick={() => onRemove()}>
              <Icon icon="times-white" />
            </RoundButton>
            <Button m={3} width="auto" onClick={() => onClearAll()}>
              {t('common:clear_all')}
            </Button>
          </Flex>
        </Columns>
      </Flex>
    </PropertyGroup>
  );
};

export default ActionPanel;
