import React from 'react';
import {
  Box,
  Card,
  Flex,
  Heading,
  Label,
  ProgressiveImage,
  Select,
} from 'elcano';
import { useTranslation } from '../hooks';

function Products({ products, handleOptions, optionQuantities }) {
  const { t } = useTranslation();
  return (
    <Flex width={1}>
      {products &&
        products.map((item) => (
          <Card my={5} p={4} width={1} key={item.id}>
            <Flex flexWrap="wrap">
              <Flex width={1 / 2}>
                {item.images &&
                  item.images.map((image) => (
                    <ProgressiveImage image={image} width={100} />
                  ))}
              </Flex>
              <Flex width={1 / 2} flexWrap="wrap">
                <Heading width={1} my={3}>
                  {item.name}
                </Heading>
                {/* <Text width={1} mr={2}>
                  &euro;
                  {`${item.price} x ${item.quantity || 1}`}
                </Text> */}

                {item.sizeOptions && item.sizeOptions.length ? (
                  item.sizeOptions.map((option) => (
                    <Box key={option} m={1} width={[1, 1, 3 / 4]}>
                      <Label htmlFor="quantity" mb={2}>
                        {option}
                      </Label>
                      <Select
                        mb={3}
                        id="quantity"
                        name="quantity"
                        defaultValue={
                          (optionQuantities && optionQuantities[option]) || 1
                        }
                        onChange={(e) => handleOptions(option, e.target.value)}
                      >
                        {[...Array(101)].map((i, key) => (
                          <option key={i}>{key}</option>
                        ))}
                      </Select>
                    </Box>
                  ))
                ) : (
                  <Box m={1} width={[1, 1, 3 / 4]}>
                    <Label htmlFor="quantity" mb={2}>
                      {t('common:quantity')}
                    </Label>
                    <Select
                      mb={3}
                      id="quantity"
                      name="quantity"
                      defaultValue={
                        (optionQuantities && optionQuantities[0]) || 1
                      }
                      onChange={(e) => handleOptions(0, e.target.value)}
                    >
                      {[...Array(101)].map((i, key) => (
                        <option key={i}>{key}</option>
                      ))}
                    </Select>
                  </Box>
                )}
              </Flex>
            </Flex>
          </Card>
        ))}
    </Flex>
  );
}

export default Products;
