import React from 'react';
import { Icon } from '../components/Icon';
import {
  Bookmark,
  BookmarkOutline,
  Check,
  ChristmasTree,
  ChristmasTreeOutline,
  Circle,
  CircleOutline,
  Heart,
  HeartOutline,
  Leaf,
  LightBulb,
  Moon,
  MoonOutline,
  Snowflake,
  SquareOutline,
  Star,
  StarOutline,
  Triangle,
  TriangleOutline,
} from '../components/icons';
// TODO: Move to elcano

export interface Shape {
  type: string;
  icon: JSX.Element;
  path?: string;
}

export const shapes: Shape[] = [
  {
    type: 'path',
    icon: <Circle size={50} />,
    path:
      'M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10Z',
  },
  {
    type: 'path',
    path:
      'M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10Z',
    icon: <CircleOutline size={50} />,
  },
  {
    type: 'rect',
    icon: <Icon icon="rect" size={80} />,
  },
  {
    type: 'path',
    path:
      'M2 0H16C17.1046 0 18 0.89543 18 2V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0ZM16 16V2H2V16H16Z',
    icon: <SquareOutline size={50} />,
  },
  {
    type: 'triangle',
    icon: <Triangle size={50} />,
  },
  {
    type: 'path',
    path:
      'M11.8128 0.37L21.9052 17.63C22.0351 17.8667 22.0351 18.1533 21.9052 18.39L21.7651 18.62C21.6355 18.8573 21.3854 19.0035 21.115 19H0.8902C0.619822 19.0035 0.369668 18.8573 0.240046 18.62L0.100013 18.39C-0.0298791 18.1533 -0.0298791 17.8667 0.100013 17.63L10.2124 0.37C10.3492 0.141211 10.5959 0.0008087 10.8626 0H11.1626C11.4293 0.0008087 11.676 0.141211 11.8128 0.37ZM11.0026 3L2.79065 17H19.2045L11.0026 3Z',
    icon: <TriangleOutline size={50} />,
  },
  {
    type: 'path',
    path:
      'M0.201432 7.85425C0.0324796 7.73123 -0.0397528 7.51452 0.021606 7.31473L0.101529 7.06496C0.159961 6.86756 0.335635 6.72781 0.541103 6.71527L6.92493 6.20573L9.37256 0.321007C9.45466 0.114853 9.66074 -0.0144568 9.88206 0.00129377H10.1418C10.3505 -0.00313381 10.539 0.12523 10.6114 0.321007L13.069 6.20573L19.4528 6.71527C19.6583 6.72781 19.8339 6.86756 19.8924 7.06496L19.9723 7.31473C20.0403 7.50821 19.9805 7.72355 19.8224 7.85425L15.0071 11.9905L16.4857 18.195C16.5344 18.3936 16.4596 18.6021 16.2959 18.7245L16.0061 18.8843C15.8371 18.9986 15.6157 18.9986 15.4467 18.8843L10.0119 15.5873L4.54723 18.9143C4.37823 19.0286 4.15676 19.0286 3.98777 18.9143L3.76798 18.7645C3.60419 18.642 3.52945 18.4335 3.57816 18.2349L5.01677 11.9905L0.201432 7.85425Z',
    icon: <Star size={50} />,
  },
  {
    type: 'path',
    path:
      'M9.39166 0.319989C9.46995 0.124384 9.66017 -0.00277823 9.87035 -1.09465e-05H10.1296C10.3379 -0.0044425 10.5261 0.124037 10.5983 0.319989L13.0815 6.20999L19.454 6.71999C19.6591 6.73254 19.8344 6.87241 19.8928 7.06999L19.9725 7.31999C20.0381 7.50868 19.9829 7.71839 19.8329 7.84999L14.9863 12L16.4223 18.22C16.4709 18.4188 16.3963 18.6275 16.2328 18.75L16.0134 18.9C15.9282 18.967 15.8225 19.0023 15.7143 19C15.6229 19.0019 15.533 18.9776 15.455 18.93L9.99999 15.6L4.57492 18.93C4.49692 18.9776 4.40695 19.0019 4.31563 19C4.20738 19.0023 4.10167 18.967 4.01646 18.9L3.76714 18.75C3.60364 18.6275 3.52904 18.4188 3.57766 18.22L5.01371 12L0.177024 7.85999C0.0192942 7.72917 -0.0404113 7.51364 0.027436 7.31999L0.137134 7.06999C0.195462 6.87241 0.370823 6.73254 0.575926 6.71999L6.94839 6.20999L9.39166 0.319989ZM16.6517 8.24999L11.8549 7.86999L9.99999 3.40999L8.14509 7.86999L3.3483 8.24999L7.00822 11.38L5.88132 16.06L9.99999 13.55L14.1087 16.06L12.9918 11.38L16.6517 8.24999Z',
    icon: <StarOutline size={50} />,
  },
  {
    type: 'path',
    path:
      'M20 5.5C20 2.46243 17.5376 0 14.5 0C12.7819 0.0535358 11.1586 0.800238 10 2.07C8.84137 0.800238 7.2181 0.0535358 5.5 0C2.46243 0 0 2.46243 0 5.5C0 9.42 4.75 13.75 7 16L9.28 18.28C9.42052 18.4207 9.61116 18.4998 9.81 18.5H10.19C10.3888 18.4998 10.5795 18.4207 10.72 18.28L13 16C15.25 13.75 20 9.42 20 5.5Z',
    icon: <Heart size={50} />,
  },
  {
    type: 'path',
    path:
      'M10 2.07C11.1586 0.800238 12.7819 0.0535358 14.5 0C17.5376 0 20 2.46243 20 5.5C20 9.09623 16.0022 13.0375 13.6046 15.4013C13.3887 15.6141 13.1858 15.8142 13 16L10.72 18.28C10.5795 18.4207 10.3888 18.4998 10.19 18.5H9.81C9.61116 18.4998 9.42052 18.4207 9.28 18.28L7 16C6.81416 15.8142 6.61127 15.6141 6.3954 15.4013C3.99775 13.0375 0 9.09623 0 5.5C0 2.46243 2.46243 0 5.5 0C7.2181 0.0535358 8.84137 0.800238 10 2.07ZM10 16.17L12.2 14C14.23 12 18 8.26 18 5.5C18 4.56652 17.6272 3.67171 16.9643 3.01446C16.3014 2.35721 15.4034 1.992 14.47 2C12.9153 2.14433 11.5119 2.99152 10.66 4.3C10.5646 4.42469 10.417 4.49846 10.26 4.5H9.81C9.57636 4.49937 9.35565 4.39269 9.21 4.21C8.3716 2.94974 7.00743 2.13712 5.5 2C3.567 2 2 3.567 2 5.5C2 8.26 5.77 12 7.8 14L10 16.17Z',
    icon: <HeartOutline size={50} />,
  },
  {
    type: 'path',
    path:
      'M16 19C16.0007 19.3639 15.8037 19.6994 15.4856 19.8762C15.1676 20.0529 14.7786 20.0429 14.47 19.85L8.53 16.13C8.20573 15.9273 7.79427 15.9273 7.47 16.13L1.53 19.85C1.22143 20.0429 0.832448 20.0529 0.514355 19.8762C0.196263 19.6994 -0.000728975 19.3639 1.09473e-07 19V2C1.09473e-07 0.89543 0.89543 0 2 0H14C15.1046 0 16 0.89543 16 2V19Z',
    icon: <Bookmark size={50} />,
  },
  {
    type: 'path',
    path:
      'M2 0H14C15.1046 0 16 0.89543 16 2V19C16 19.5523 15.5523 20 15 20C14.8128 20.0006 14.6291 19.9486 14.47 19.85L8.53 16.13C8.20573 15.9273 7.79427 15.9273 7.47 16.13L1.53 19.85C1.37086 19.9486 1.18723 20.0006 1 20C0.447715 20 0 19.5523 0 19V2C0 0.89543 0.89543 0 2 0ZM14 17.19V2H2V17.19L6.41 14.44C7.38281 13.832 8.61719 13.832 9.59 14.44L14 17.19Z',
    icon: <BookmarkOutline size={50} />,
  },
  {
    type: 'path',
    path:
      'M13.911 15.3801L13.7714 15.6301C13.6351 15.8588 13.3892 15.9992 13.1234 16.0001H8.13893L8.41807 19.4601C8.44851 19.7175 8.27372 19.9542 8.0193 20.0001H6.0255C5.88683 20.0008 5.75439 19.9424 5.6612 19.8393C5.56801 19.7363 5.52287 19.5984 5.53702 19.4601L5.85603 16.0001H0.871518C0.605783 15.9992 0.359895 15.8588 0.223532 15.6301L0.0839657 15.3801C-0.0556005 15.1401 -0.0556005 14.8601 0.353129 14.1601L2.82545 10.0001H2.20737C1.94163 9.99925 1.69574 9.85885 1.55938 9.63006L1.41981 9.38006C1.28025 9.14006 1.28025 8.86006 1.68898 8.16006L6.23485 0.370056C6.36824 0.138247 6.61603 -0.00323834 6.88284 5.63109e-05H7.16197C7.42522 0.000362877 7.66836 0.141365 7.79999 0.370056L12.4356 8.37006C12.7546 8.91006 12.7546 9.19006 12.615 9.43006L12.4755 9.68006C12.3391 9.90885 12.0932 10.0492 11.8275 10.0501H11.2094L13.7316 14.3701C14.0506 14.8601 14.0506 15.1401 13.911 15.3801Z',
    icon: <ChristmasTree size={50} />,
  },
  {
    type: 'path',
    path:
      'M12.1874 10.0001L14.7145 14.3201C15.0342 14.8601 15.0342 15.1401 14.9443 15.3801L14.8044 15.6301C14.6678 15.8588 14.4214 15.9992 14.1552 16.0001H9.1608L9.48044 19.4601C9.49462 19.5984 9.44939 19.7363 9.35602 19.8393C9.26264 19.9424 9.12994 20.0008 8.99099 20.0001H6.99325C6.7343 19.9589 6.55372 19.7208 6.58371 19.4601L6.86339 16.0001H1.86903C1.60277 15.9992 1.3564 15.8588 1.21976 15.6301L1.07992 15.3801C0.940078 15.1601 0.940078 14.8601 1.34962 14.1601L3.78687 10.0001H3.16756C2.9013 9.99925 2.65493 9.85885 2.5183 9.63006L2.37846 9.38006C2.23861 9.16006 2.23861 8.86006 2.64815 8.16006L7.20301 0.370056C7.33667 0.138247 7.58494 -0.00323834 7.85228 5.63109e-05H8.13196C8.39574 0.000362877 8.63936 0.141365 8.77124 0.370056L13.416 8.32006C13.7356 8.86006 13.7356 9.14006 13.5958 9.38006L13.456 9.63006C13.3193 9.85885 13.0729 9.99925 12.8067 10.0001H12.1874ZM7.05318 8.38006L3.76689 14.0001H12.2174L8.93106 8.38006C8.88445 8.30342 8.88243 8.20765 8.92578 8.12911C8.96914 8.05057 9.0512 8.00132 9.14083 8.00006H10.9188L7.99212 3.00006L5.05543 8.00006H6.83343C6.92228 8.00366 7.0031 8.05253 7.04766 8.12957C7.09221 8.20662 7.09429 8.30112 7.05318 8.38006Z',
    icon: <ChristmasTreeOutline size={50} />,
  },
  {
    type: 'path',
    path:
      'M20 12.7481C18.5872 17.4116 14.0731 20.4279 9.21976 19.9513C4.36641 19.4747 0.526434 15.6381 0.0494193 10.7889C-0.427595 5.93981 2.59129 1.42964 7.25891 0.0180811C7.44011 -0.0299699 7.63329 0.0192289 7.76935 0.148081L7.85943 0.238081C7.96659 0.350619 8.02112 0.503181 8.00956 0.658081C7.62374 3.76601 8.69114 6.8784 10.9036 9.09678C13.1161 11.3152 16.2274 12.3926 19.3394 12.0181C19.4945 12.0065 19.6472 12.061 19.7598 12.1681L19.8499 12.2581C19.9788 12.394 20.0281 12.587 19.98 12.7681L20 12.7481Z',
    icon: <Moon size={50} />,
  },
  {
    type: 'path',
    path:
      'M7.25259 0H7.39259C7.52425 0.00293956 7.64966 0.0567369 7.74259 0.150131L7.83259 0.240209C7.93965 0.352845 7.99414 0.50554 7.98259 0.660575C7.63041 3.5594 8.53663 6.47027 10.4717 8.65579C12.4067 10.8413 15.185 12.0918 18.1026 12.0905C18.5105 12.0888 18.9179 12.062 19.3226 12.0105H19.3826C19.5217 12 19.659 12.047 19.7626 12.1406L19.8526 12.2306C19.9814 12.3667 20.0306 12.5599 19.9826 12.7411C18.571 17.4087 14.0609 20.4276 9.21173 19.9506C4.3626 19.4736 0.525975 15.6336 0.0493763 10.7802C-0.427223 5.92689 2.58904 1.41279 7.25259 0ZM9.52259 10.4991C7.45084 8.43468 6.19373 5.6908 5.98259 2.77241C3.03076 4.51657 1.47436 7.91439 2.08052 11.2911C2.68667 14.6679 5.32745 17.311 8.70127 17.9177C12.0751 18.5244 15.4699 16.9666 17.2126 14.0122C14.3106 13.8046 11.5806 12.5574 9.52259 10.4991Z',
    icon: <MoonOutline size={50} />,
  },
  {
    type: 'path',
    path:
      'M14 6.5V7.5C14 7.77614 13.7761 8 13.5 8H8V13.5C8 13.7761 7.77614 14 7.5 14H6.5C6.22386 14 6 13.7761 6 13.5V8H0.5C0.223858 8 0 7.77614 0 7.5V6.5C0 6.22386 0.223858 6 0.5 6H6V0.5C6 0.223858 6.22386 0 6.5 0H7.5C7.77614 0 8 0.223858 8 0.5V6H13.5C13.7761 6 14 6.22386 14 6.5Z',
    icon: <Icon icon="plus" size={60} />,
  },
  {
    type: 'path',
    path:
      'M16.85 2.24999L6.24997 12.85C6.15609 12.9447 6.02829 12.9979 5.89497 12.9979C5.76166 12.9979 5.63386 12.9447 5.53997 12.85L0.149974 7.45999C0.055318 7.36611 0.0020752 7.23831 0.0020752 7.10499C0.0020752 6.97168 0.055318 6.84388 0.149974 6.74999L0.849974 6.04999C0.943858 5.95534 1.07166 5.9021 1.20497 5.9021C1.33829 5.9021 1.46609 5.95534 1.55997 6.04999L5.88997 10.38L15.44 0.829995C15.6378 0.638211 15.9522 0.638211 16.15 0.829995L16.85 1.53999C16.9446 1.63388 16.9979 1.76168 16.9979 1.89499C16.9979 2.02831 16.9446 2.15611 16.85 2.24999Z',
    icon: <Check size={50} />,
  },
  {
    type: 'path',
    path:
      'M18 8.69V9.19C18 9.46614 17.7761 9.69 17.5 9.69H13.42L15.85 12.12C15.9467 12.2107 16.0015 12.3374 16.0015 12.47C16.0015 12.6026 15.9467 12.7293 15.85 12.82L15.5 13.18C15.3022 13.3718 14.9878 13.3718 14.79 13.18L11.3 9.69H9.69V11.18L13.18 14.67C13.3706 14.8644 13.3706 15.1756 13.18 15.37L12.82 15.73C12.6256 15.9206 12.3144 15.9206 12.12 15.73L9.69 13.3V17.53C9.69 17.6547 9.64048 17.7742 9.55234 17.8623C9.4642 17.9505 9.34465 18 9.22 18H8.65C8.39434 17.9946 8.18994 17.7857 8.19 17.53V13.53L5.88 15.83C5.78927 15.9267 5.66259 15.9815 5.53 15.9815C5.39741 15.9815 5.27073 15.9267 5.18 15.83L4.82 15.48C4.62822 15.2822 4.62822 14.9678 4.82 14.77L8.19 11.41V9.69H6.82L3.33 13.18C3.13558 13.3706 2.82442 13.3706 2.63 13.18L2.27 12.82C2.07943 12.6256 2.07943 12.3144 2.27 12.12L4.7 9.69H0.47C0.210426 9.69 0 9.47957 0 9.22V8.65C0.00544081 8.39434 0.214282 8.18994 0.47 8.19H4.47L2.15 5.88C2.05332 5.78927 1.99848 5.66259 1.99848 5.53C1.99848 5.39741 2.05332 5.27073 2.15 5.18L2.5 4.82C2.69781 4.62822 3.01219 4.62822 3.21 4.82L6.57 8.19H8.19V6.7L4.82 3.33C4.62943 3.13558 4.62943 2.82442 4.82 2.63L5.18 2.27C5.37442 2.07943 5.68558 2.07943 5.88 2.27L8.19 4.57V0.5C8.19 0.223858 8.41386 0 8.69 0H9.19C9.46614 0 9.69 0.223858 9.69 0.5V4.58L12.12 2.15C12.2107 2.05332 12.3374 1.99848 12.47 1.99848C12.6026 1.99848 12.7293 2.05332 12.82 2.15L13.18 2.5C13.3718 2.69781 13.3718 3.01219 13.18 3.21L9.69 6.7V8.19H11.3L14.67 4.82C14.8644 4.62943 15.1756 4.62943 15.37 4.82L15.73 5.18C15.9206 5.37442 15.9206 5.68558 15.73 5.88L13.43 8.19H17.5C17.6326 8.19 17.7598 8.24268 17.8536 8.33645C17.9473 8.43021 18 8.55739 18 8.69Z',
    icon: <Snowflake size={50} />,
  },
  {
    type: 'path',
    path:
      'M11.5 3H10.5C10.2239 3 10 2.77614 10 2.5V0.5C10 0.223858 10.2239 0 10.5 0H11.5C11.7761 0 12 0.223858 12 0.5V2.5C12 2.77614 11.7761 3 11.5 3ZM5.24458 9.33379C5.98571 6.76996 8.33119 5.00382 11 5C13.6688 5.00382 16.0143 6.76996 16.7554 9.33379C17.4965 11.8976 16.4551 14.6428 14.2 16.07C13.761 16.3422 13.4957 16.8235 13.5 17.34V17.5C13.5 17.7761 13.2761 18 13 18H9C8.72386 18 8.5 17.7761 8.5 17.5V17.34C8.50039 16.8245 8.23606 16.3449 7.8 16.07C5.54485 14.6428 4.50345 11.8976 5.24458 9.33379ZM7 11C7 13.2091 8.79086 15 11 15C12.0609 15 13.0783 14.5786 13.8284 13.8284C14.5786 13.0783 15 12.0609 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11ZM9 20.59C9.0011 20.8523 9.10526 21.1037 9.29 21.29L9.71 21.71C9.89626 21.8947 10.1477 21.9989 10.41 22H11.59C11.8523 21.9989 12.1037 21.8947 12.29 21.71L12.71 21.29C12.8947 21.1037 12.9989 20.8523 13 20.59V20H9V20.59ZM19.13 3.58L18.42 2.87C18.3293 2.77332 18.2026 2.71848 18.07 2.71848C17.9374 2.71848 17.8107 2.77332 17.72 2.87L16.3 4.28C16.1082 4.47781 16.1082 4.79219 16.3 4.99L17 5.7C17.1978 5.89178 17.5122 5.89178 17.71 5.7L19.12 4.28C19.218 4.19066 19.2746 4.06476 19.2765 3.93216C19.2784 3.79957 19.2254 3.67211 19.13 3.58ZM4.28 2.87L5.7 4.28C5.89834 4.47902 5.89834 4.80098 5.7 5L5 5.7C4.80098 5.89834 4.47902 5.89834 4.28 5.7L2.87 4.28C2.77332 4.18927 2.71848 4.06259 2.71848 3.93C2.71848 3.79741 2.77332 3.67073 2.87 3.58L3.58 2.87C3.67073 2.77332 3.79741 2.71848 3.93 2.71848C4.06259 2.71848 4.18927 2.77332 4.28 2.87ZM21.5 10H19.5C19.2239 10 19 10.2239 19 10.5V11.5C19 11.7761 19.2239 12 19.5 12H21.5C21.7761 12 22 11.7761 22 11.5V10.5C22 10.2239 21.7761 10 21.5 10ZM0.5 10H2.5C2.77614 10 3 10.2239 3 10.5V11.5C3 11.7761 2.77614 12 2.5 12H0.5C0.223858 12 0 11.7761 0 11.5V10.5C0 10.2239 0.223858 10 0.5 10Z',
    icon: <LightBulb size={50} />,
  },
  {
    type: 'path',
    path:
      'M17.6 0.830042C17.7645 0.993588 17.867 1.20923 17.89 1.44004C18.13 3.92004 18.27 9.69004 15 12.94C12.5338 15.4147 8.62057 15.6921 5.83 13.59L1.56 17.85C1.46612 17.9447 1.33832 17.9979 1.205 17.9979C1.07168 17.9979 0.943883 17.9447 0.85 17.85L0.15 17.15C0.0553438 17.0562 0.00210094 16.9284 0.00210094 16.795C0.00210094 16.6617 0.0553438 16.5339 0.15 16.44L4.41 12.2C2.29025 9.40389 2.56816 5.47037 5.06 3.00004C7.58 0.460042 11.62 4.20562e-05 14.3 4.20562e-05C15.0547 -0.00142106 15.809 0.0352925 16.56 0.110042C16.7895 0.130312 17.005 0.22922 17.17 0.390042L17.6 0.830042ZM7.25 12.16C9.24528 13.501 11.9124 13.2322 13.6 11.52C16.14 9.00004 16.08 4.07004 15.94 2.06004C15.4 2.00004 14.85 2.00004 14.3 2.00004C12.27 2.00004 8.56 2.31004 6.48 4.40004C4.76785 6.08761 4.49904 8.75476 5.84 10.75L9.44 7.15004C9.53388 7.05539 9.66168 7.00214 9.795 7.00214C9.92832 7.00214 10.0561 7.05539 10.15 7.15004L10.85 7.85004C10.9447 7.94393 10.9979 8.07172 10.9979 8.20504C10.9979 8.33836 10.9447 8.46616 10.85 8.56004L7.25 12.16Z',
    icon: <Leaf size={50} />,
  },
];

export default shapes;
